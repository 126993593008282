import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import {
  Form,
  Input,
  Button,
  DatePicker,
  Table,
  Radio,
  Spin,
  Pagination,
} from "antd";
import API, { RANK, USER } from "../../network/api";
import moment from "moment";
import "moment-timezone";
import { downloadExcel } from "react-export-table-to-excel";
import { TEXT_DEF } from "../../constant/text";
import settingIcon from "../../image/setting.png";
import ModalCommon from "../../components/Modal";

const AccountList = () => {
  const auth = JSON.parse(localStorage.getItem("auth"));

  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const navigate = useNavigate();
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [userList, setUserList] = useState([]);
  const [adminList, setAdminList] = useState([]);
  const indexTabLocal = localStorage.getItem("indexTabAcc") || 1;
  const [indexTab, setIndexTab] = useState(Number(indexTabLocal));
  localStorage.removeItem("indexTabAcc");
  const roleLocal = localStorage.getItem("roles");
  const roleArr = roleLocal?.split(",");
  const [pageUser, setPageUser] = useState(1);
  const [totalRecordUser, setTotalRecordUser] = useState(0);
  const [pageAdmin, setPageAdmin] = useState(1);
  const [totalRecordAdmin, setTotalRecordAdmin] = useState(0);
  const locale = {
    emptyText: TEXT_DEF.text0035,
  };
  const [loading, setLoading] = useState(false);
  const [dataFilter, setDataFilter] = useState();
  const [idField, setIdField] = useState();
  const [isShowModalConfirm, setIsShowModalConfirm] = useState(false);
  const [isShowModalSuccess, setIsShowModalSuccess] = useState(false);

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    if (indexTabLocal === 1) {
      if (
        auth?.role === "admin" &&
        (roleArr?.indexOf("user_view") || roleArr?.indexOf("user_edit"))
      ) {
        setIndexTab(1);
      } else if (
        auth?.role === "admin" &&
        (roleArr?.indexOf("admin_permissions_view") ||
          roleArr?.indexOf("admin_permissions_edit"))
      ) {
        setIndexTab(2);
      }
    }
  }, []);

  useEffect(() => {
    getListDataUser();
  }, [pageUser, pageAdmin, dataFilter, indexTab]);

  const getListDataUser = async () => {
    setLoading(true);
    const keyword = dataFilter
      ? dataFilter
      : encodeURIComponent(
        JSON.stringify({ role: indexTab === 1 ? "member" : "admin" })
      );
    const dataList1 = API.get(
      `${USER}/all?take=10&skip=${indexTab === 1 ? pageUser : pageAdmin
      }&order=DESC&keyword=${keyword}`
    );

    await Promise.all([dataList1])
      .then((response) => {
        const res = response[0].data.data.result[0];
        if (res) {
          let dataUserCV = [],
            dataAdminCV = [];
          res?.map((item) => {
            if (item?.role === "member") {
              const user = {
                key: item?.id,
                username: item?.username,
                userId: item?.id,
                createdAt: item?.createdAt,
              };
              dataUserCV.push(user);
              // dataUserCV = dataUserCV.sort((a, b) => (moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf()))
            } else if (item?.role === "admin") {
              const admin = {
                key: item?.id,
                id: item?.id,
                username: item?.username,
                role: item?.role === "admin" ? "cs" : "admin",
                createdAt: item?.createdAt,
              };
              dataAdminCV.push(admin);
            }
          });

          setUserList(dataUserCV);
          setTotalRecordUser(response[0].data.data.result[1]);

          setAdminList(dataAdminCV);
          setTotalRecordAdmin(response[0].data.data.result[1]);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const onChangePageUser = (page) => {
    setPageUser(page);
  };

  const onChangePageAdmin = (page) => {
    setPageAdmin(page);
  };

  const showTotal = (total) => `Tổng cộng ${total} tài khoản`;

  const onSearch = () => {
    let params = { role: indexTab === 1 ? "member" : "admin" };
    if (form.getFieldValue("usernameFilter"))
      params.username = form
        .getFieldValue("usernameFilter")
        .trim()
        .toLowerCase();
    if (dateFrom) {
      params.startDate = moment(dateFrom).format("YYYY-MM-DD");
      if (!dateTo) params.endDate = "2125-12-31";
    }
    if (dateTo) {
      if (!dateFrom) params.endDate = "2023-01-01";
      params.endDate = moment(dateTo).format("YYYY-MM-DD");
    }

    if (indexTab === 1) {
      setPageUser(1);
    } else {
      setPageAdmin(1);
    }

    if (params?.username || params?.startDate || params?.endDate) {
      setDataFilter(JSON.stringify(params));
    } else {
      setDataFilter(null);
    }
  };

  const handleShowModal = (id) => {
    console.log(id);
    setIsShowModalConfirm(true);
    setIdField(id);
  };

  const handleDeleteAccount = async () => {
    try {
      setIsShowModalConfirm(false);
      await API.delete(`${USER}/${idField}`);
      await getListDataUser();
    } catch (err) {
      console.log(err);
    } finally {
      setIsShowModalSuccess(true);
    }
  };

  const userColums = [
    {
      title: "STT",
      align: "center",
      key: "order",
      render: (text, record, index) => (
        <span>{(pageUser - 1) * 10 + (index + 1)}</span>
      ),
    },
    {
      title: TEXT_DEF.text0002,
      dataIndex: "username",
      key: "username",
    },
    {
      title: TEXT_DEF.text0013,
      key: "createdAt",
      render: (record) =>
        moment(record?.createdAt).utcOffset(7).format("DD-MM-YYYY HH:mm:ss"),
    },
  ];

  const adminColums = [
    {
      title: "STT",
      align: "center",
      key: "order",
      render: (text, record, index) => (
        <span>{(pageAdmin - 1) * 10 + (index + 1)}</span>
      ),
    },
    {
      title: TEXT_DEF.text0002,
      dataIndex: "username",
      key: "username",
    },
    {
      title: TEXT_DEF.text0119,
      key: "createdAt",
      render: (record) =>
        moment(record?.createdAt).utcOffset(7).format("DD-MM-YYYY HH:mm:ss"),
    },
    {
      title: TEXT_DEF.text0153,
      key: "setting",
      align: "center",
      render: (record) =>
        auth?.role === "supper" ||
          roleArr?.indexOf("admin_permissions_view") > -1 ||
          roleArr?.indexOf("admin_permissions_edit") > -1 ? (
          <img
            style={{ height: 20, cursor: "pointer" }}
            src={settingIcon}
            alt="setting"
            onClick={() => {
              localStorage.setItem("indexLocalEdit", "2");
              navigate(`/setPermission/${record.id}`);
            }}
          />
        ) : null,
    },
    {
      title: "Hành động",
      key: "action",
      align: "center",
      render: (text, record) =>
        auth?.role === "supper" ||
          (auth?.role === "admin" && roleArr?.indexOf("user_edit") > -1) ? (
          <div className="flex w-full items-center justify-center">
            <Button danger onClick={() => handleShowModal(record.key)}>
              Xóa
            </Button>
          </div>
        ) : null,
    },
  ];

  const dollarUS = Intl.NumberFormat("en-US");

  const onChangeDate = (e, type) => {
    if (e) {
      if (type === "from") setDateFrom(moment(e.$d).startOf("day").format('yyyy-MM-DD'));
      else setDateTo(moment(e.$d).endOf("day").format('yyyy-MM-DD'));
    } else {
      if (type === "from") setDateFrom(null);
      else setDateTo(null);
    }
  };

  const addNewBank = () => {
    localStorage.setItem("indexLocalCreate", indexTab);
    navigate("/createAccount");
  };

  // const openModalDel = (account) => {
  //   setAccountDel(account)
  //   setIsShowModalDel(true)
  // }

  // const onDelAcc = async () => {

  // }

  const onChangeTab = (e) => {
    setPageUser(1);
    setPageAdmin(1);
    setIndexTab(e.target.value);
  };

  const handleDownloadExcel = async () => {
    const keyword = dataFilter
      ? dataFilter
      : encodeURIComponent(
        JSON.stringify({ role: indexTab === 1 ? "member" : "admin" })
      );

    await API.get(`${USER}/all?take=10000&skip=1&order=DESC&keyword=${keyword}`)
      .then((response) => {
        const res = response.data.data?.result[0];
        if (res) {
          let dataUserCV = [];
          res?.map((item) => {
            if (item?.role === "member") {
              const user = {
                username: item?.username,
                createdAt: moment(item?.createdAt)
                  .utcOffset(7)
                  .format("DD-MM-YYYY HH:mm:ss"),
              };
              dataUserCV.push(user);
            }
          });

          const header = [TEXT_DEF.text0002, TEXT_DEF.text0013];
          downloadExcel({
            fileName: "Tài khoản",
            sheet: "Tài khoản",
            tablePayload: {
              header: header,
              body: dataUserCV,
            },
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const renderAccUser = () => {
    return (
      <>
        <div className="col-12">
          <Form
            form={form}
            layout="inline"
            autoComplete="off"
            onFinish={() => onSearch()}
          >
            <div
              className="col-12"
              style={{ marginBottom: 15, display: "flex" }}
            >
              <div className="col-4">
                <Form.Item name="usernameFilter" label={TEXT_DEF.text0002}>
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item name="dateFrom" label={TEXT_DEF.text0032}>
                  <DatePicker
                    placeholder=""
                    onChange={(e) => onChangeDate(e, "from")}
                    format={"DD-MM-YYYY"}
                    style={{ width: "100%" }}
                    disabledTime={(d) =>
                      !d ||
                      d.isSameOrAfter(
                        dateTo ? moment(dateTo).add(1, "d") : moment()
                      )
                    }
                  />
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item name="dateTo" label={TEXT_DEF.text0033}>
                  <DatePicker
                    placeholder=""
                    onChange={(e) => onChangeDate(e, "to")}
                    format={"DD-MM-YYYY"}
                    style={{ width: "100%" }}
                    disabledTime={(d) =>
                      !d ||
                      d.isSameOrBefore(dateFrom ? dateFrom : null) ||
                      d.isSameOrAfter(moment())
                    }
                  />
                </Form.Item>
              </div>
            </div>
            <div className="payment-search">
              <Button htmlType="submit" type="primary" >
                {TEXT_DEF.text0031}
              </Button>
              <Button
                style={{ marginLeft: 20 }}
                onClick={handleDownloadExcel}
                htmlType="button"
                type="primary"
              >
                {TEXT_DEF.text0050}
              </Button>
            </div>
          </Form>
        </div>
        {/* {auth?.role === "supper" ? <div style={{ display: 'flex', justifyContent: 'end', marginTop: '2rem' }}>
          <Button type="primary" onClick={addNewBank}>
            {TEXT_DEF.text0041}
          </Button>
        </div> : null } */}
        <Spin spinning={loading} delay={500} size="large">
          <div style={{ display: "flex", width: "100%", marginTop: 20 }}>
            <Table
              key="userList"
              locale={locale}
              columns={userColums}
              dataSource={userList}
              pagination={false}
              bordered
              style={{ width: "100%" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              marginTop: 15,
              justifyContent: "flex-end",
            }}
          >
            <Pagination
              current={pageUser}
              total={totalRecordUser}
              onChange={onChangePageUser}
              defaultPageSize={10}
              showSizeChanger={false}
              showTotal={showTotal}
            />
          </div>
        </Spin>
      </>
    );
  };

  const renderAccAdmin = () => {
    return (
      <>
        <div className="col-12">
          <Form
            form={form}
            layout="inline"
            autoComplete="off"
            onFinish={() => onSearch()}
          >
            <div
              className="col-12"
              style={{ marginBottom: 15, display: "flex" }}
            >
              <div className="col-4">
                <Form.Item name="usernameFilter" label={TEXT_DEF.text0002}>
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item name="dateFrom" label={TEXT_DEF.text0032}>
                  <DatePicker
                    placeholder=""
                    onChange={(e) => onChangeDate(e, "from")}
                    format={"DD-MM-YYYY"}
                    style={{ width: "100%" }}
                    disabledTime={(d) =>
                      !d ||
                      d.isSameOrAfter(
                        dateTo ? moment(dateTo).add(1, "d") : moment()
                      )
                    }
                  />
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item name="dateTo" label={TEXT_DEF.text0033}>
                  <DatePicker
                    placeholder=""
                    onChange={(e) => onChangeDate(e, "to")}
                    format={"DD-MM-YYYY"}
                    style={{ width: "100%" }}
                    disabledTime={(d) =>
                      !d ||
                      d.isSameOrBefore(dateFrom ? dateFrom : null) ||
                      d.isSameOrAfter(moment())
                    }
                  />
                </Form.Item>
              </div>
            </div>
            <div className="payment-search">
              <Button htmlType="submit" type="primary">
                {TEXT_DEF.text0031}
              </Button>
            </div>
          </Form>
        </div>
        {auth?.role === "supper" || roleArr?.indexOf("user_edit") > -1 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "2rem",
            }}
          >
            <Button type="primary" onClick={addNewBank}>
              {TEXT_DEF.text0010}
            </Button>
          </div>
        ) : null}
        <div style={{ display: "flex", width: "100%", marginTop: 20 }}>
          <Table
            key="listAdmin"
            locale={locale}
            columns={adminColums}
            dataSource={adminList}
            pagination={false}
            bordered
            style={{ width: "100%" }}
          />
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            marginTop: 15,
            justifyContent: "flex-end",
          }}
        >
          <Pagination
            current={pageAdmin}
            total={totalRecordAdmin}
            onChange={onChangePageAdmin}
            defaultPageSize={10}
            showSizeChanger={false}
            showTotal={showTotal}
          />
        </div>
      </>
    );
  };

  return (
    <div className="col-12">
      {auth?.role === "supper" ||
        (auth?.role === "admin" &&
          (roleArr?.indexOf("user_view") > -1 ||
            roleArr?.indexOf("user_edit") > -1) &&
          (roleArr?.indexOf("admin_permissions_view") > -1 ||
            roleArr?.indexOf("admin_permissions_edit") > -1)) ? (
        <div
          className="col-12"
          style={{
            borderBottom: "1px solid #CCC",
            paddingBottom: "2rem",
            marginBottom: "2rem",
          }}
        >
          <Radio.Group value={indexTab} onChange={onChangeTab}>
            <Radio.Button value={1}>{TEXT_DEF.text0036}</Radio.Button>
            <Radio.Button value={2}>{TEXT_DEF.text0037}</Radio.Button>
          </Radio.Group>
        </div>
      ) : null}

      {indexTab === 1 ? renderAccUser() : renderAccAdmin()}
      {isShowModalConfirm && (
        <ModalCommon
          isShow={isShowModalConfirm}
          message={TEXT_DEF.text_delete_user}
          txtBtnAcc={TEXT_DEF.text0008}
          textBtnClose={TEXT_DEF.text0023}
          actionNext={handleDeleteAccount}
          close={() => setIsShowModalConfirm(false)}
        />
      )}
      {isShowModalSuccess && (
        <ModalCommon
          isShow={isShowModalSuccess}
          message={TEXT_DEF.text_modal_success}
          txtBtnAcc={TEXT_DEF.text0007}
          actionNext={() => {
            setIsShowModalSuccess(false);
            getListDataUser();
          }}
          close={() => {
            setIsShowModalSuccess(false);
            getListDataUser();
          }}
        />
      )}
    </div>
  );
};

export default AccountList;
