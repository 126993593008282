import { Button, InputNumber, Space, Switch, TimePicker } from "antd";
import React, { memo, useState } from "react";
import { TEXT_DEF } from "../../../constant/text";
import moment from "moment";
import dayjs from "dayjs";
// import "antd/dist/antd.css";

const TicketThree = ({
  pointMoney,
  roundArray,
  maintenanceTicket3,
  authConfig,
  setAuthConfig,
  onShowModalConfirm,
  onHandleInputChangePoint,
  onHandleChangeInputRound,
  onHandleAddRowMoney,
  onHandleRemoveItem,
  onHandleAddRound,
  onHandleRemoveItemRound,
  onHandleUpdateMainTiket3,
  onHandleUpdateAuthTiket3,
  setMaintenaaceTicket3,
  time,
  setTime,
  onSetTime,
  onShowModal,
  totalDeposit,
  setTotalDeposit,
}) => {

  const onChange = (value, timeString) => {
    if (value !== null && value !== undefined) {
      setTime({
        ...time,
        value: timeString + ":00",
      });
    } else {
      setTime({
        ...time,
        value: null,
      });
    }
    console.log("TIME", timeString)
  }

  return (
    <div className="col-12">
      <div className="col-9" style={{ display: "flex", alignItems: "center" }}>
        <div className="col-3" style={{ fontWeight: "600" }}>{TEXT_DEF.text0057}:</div>
        <div className="col-6" style={{ display: "flex" }}>
          <Switch
            checkedChildren="Bật"
            unCheckedChildren="Tắt"
            checked={maintenanceTicket3?.value}
            style={{ width: 60, marginTop: "5px" }}
            onChange={(e) =>
              setMaintenaaceTicket3((prev) => ({
                ...prev,
                value: e,
              }))
            }
          />
          <Button
            type="primary"
            onClick={(e) => onShowModal(e, "maintiket3")}
            style={{
              marginLeft: "2rem",
            }}
          >
            {TEXT_DEF.update}
          </Button>
        </div>
      </div>
      <div
        className="col-9 mt-3"
        style={{ display: "flex", alignItems: "center" }}
      >
        <div className="col-3" style={{ fontWeight: "600" }}>{TEXT_DEF.text_fake}:</div>
        <div className="col-6" style={{ display: "flex" }}>
          <Switch
            checkedChildren="Bật"
            unCheckedChildren="Tắt"
            checked={authConfig?.value}
            style={{ width: 60, marginTop: "5px" }}
            onChange={(e) =>
              setAuthConfig((prev) => ({
                ...prev,
                value: e,
              }))
            }
          />
          <Button
            type="primary"
            onClick={(e) => onShowModal(e, "authConfig3")}
            style={{
              marginLeft: "2rem",
            }}
          >
            {TEXT_DEF.update}
          </Button>
        </div>
      </div>
      <div
        className="col-9 mt-5"
        style={{ display: "flex", alignItems: "center" }}
      >
        <div className="col-3" style={{ fontWeight: "600" }}>{TEXT_DEF.time_pzize}:</div>
        <div className="col-6">
          <Space>
            <TimePicker
              size="large"
              format={'HH:mm'}
              defaultOpenValue={dayjs('00:00:00', 'HH:mm:ss')}
              value={time.value ? moment(time.value, "HH:mm:ss") : null}
              onChange={onChange}
            />
          </Space>
          <Button
            type="primary"
            style={{ marginLeft: "2rem" }}
            onClick={(e) => onShowModal(e, "configtime3")}
          >
            {TEXT_DEF.text0012}
          </Button>
        </div>
      </div>
      <div
        className="col-9 mt-5"
        style={{ display: "flex", alignItems: "center" }}
      >
        <div className="col-3" style={{ fontWeight: "600" }}>{TEXT_DEF.text0060}:</div>
        <div className="col-6" style={{ display: "flex" }}>
          <InputNumber
            min={0}
            value={totalDeposit.value}
            onChange={(e) => setTotalDeposit((item) => ({ ...item, value: e }))}
            className="flex w-full"
          />
          <Button
            type="primary"
            onClick={(e) => onShowModal(e, "totalDeposit")}
            style={{
              marginLeft: "2rem",
            }}
          >
            {TEXT_DEF.update}
          </Button>
        </div>
      </div>
      <div
        className="col-12 mt-5"
        style={{ display: "flex", alignItems: "center" }}
      >
        <div className="col-3" style={{ fontWeight: "600" }}>{TEXT_DEF.text_money}:</div>
        <div
          className="col-9 gap-2"
          style={{ display: "flex", flexDirection: "column" }}
        >
          {pointMoney &&
            pointMoney.map((item, index) => (
              <div style={{ display: "flex" }} key={item.id}>
                <div className="col-3" style={{ display: "flex" }}>
                  <div style={{ fontWeight: "600" }}>{TEXT_DEF.point}:</div>
                  <InputNumber
                    type="number"
                    min={0}
                    value={item.value}
                    defaultValue={0}
                    max={9999}
                    style={{ width: "50%", marginLeft: "1rem" }}
                    onChange={(e) =>
                      onHandleInputChangePoint(e, item.id, "value", index)
                    }
                  />
                </div>
                <div className="col-3 ml-4" style={{ display: "flex", marginLeft: '1rem', fontWeight: "600" }}>
                  <div>{TEXT_DEF.percent}:</div>
                  <InputNumber
                    type="number"
                    defaultValue={0}
                    value={item.value1}
                    min={0}
                    max={1}
                    step={0.1}
                    style={{ marginLeft: "1rem", width: "50%" }}
                    onChange={(e) =>
                      onHandleInputChangePoint(e, item.id, "value1", index)
                    }
                  />
                </div>
                <div className="col-3" style={{ display: "flex", alignItems: "center" }}>
                  <Button
                    htmlType="button"
                    danger
                    ghost
                    size="small"
                    onClick={() => onHandleRemoveItem(item.id, index)}
                  >
                    -
                  </Button>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="col-6 mt-3">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            htmlType="button"
            type="primary"
            ghost
            style={{
              marginRight: "1rem",
            }}
            onClick={onHandleAddRowMoney}
          >
            +
          </Button>
        </div>
      </div>
      <div
        className="col-12 mt-5"
        style={{ display: "flex", alignItems: "center" }}
      >
        <div className="col-3" style={{ fontWeight: "600" }}>{TEXT_DEF.round}:</div>
        <div
          className="col-9 gap-2"
          style={{ display: "flex", flexDirection: "column" }}
        >
          {roundArray &&
            roundArray.map((item, index) => (
              <div style={{ display: "flex" }} key={item.id}>
                <div className="col-3" style={{ display: "flex" }}>
                  <div style={{ fontWeight: "600" }}>{TEXT_DEF.number_round}:</div>
                  <InputNumber
                    type="number"
                    min={0}
                    defaultValue={0}
                    value={item.value}
                    max={9999}
                    style={{ width: "50%", marginLeft: "1rem" }}
                    onChange={(e) =>
                      onHandleChangeInputRound(e, item.id, "value", index)
                    }
                  />
                </div>
                <div className="col-3" style={{ display: "flex", marginLeft: '1rem' }}>
                  <div style={{ fontWeight: "600" }}>{TEXT_DEF.percent}:</div>
                  <InputNumber
                    type="number"
                    defaultValue={0}
                    value={item.value1}
                    min={0}
                    max={1}
                    step={0.1}
                    style={{ marginLeft: "1rem", width: "50%" }}
                    onChange={(e) =>
                      onHandleChangeInputRound(e, item.id, "value1", index)
                    }
                  />
                </div>
                <div className="col-3" style={{ display: "flex", alignItems: 'center' }}>
                  <Button
                    htmlType="button"
                    danger
                    ghost
                    size="small"
                    onClick={() => onHandleRemoveItemRound(item.id, index)}
                  >
                    -
                  </Button>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="col-6 mt-3">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            htmlType="button"
            type="primary"
            ghost
            style={{
              marginRight: "1rem",
            }}
            onClick={onHandleAddRound}
          >
            +
          </Button>
        </div>
      </div>
      <div
        className="col-12 mt-5"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          htmlType="submit"
          type="primary"
          onClick={(e) => onShowModalConfirm(e, "ticket3")}
        >
          {TEXT_DEF.update}
        </Button>
      </div>
    </div>
  );
};

export default memo(TicketThree);
