export const TEXT_DEF = {
  text0001: "Đăng nhập",
  text0002: "Tên đăng nhập",
  text0003: "Mật khẩu",
  text0004: "Trường này bắt buộc",
  text0005: "sai định dạng",
  text0006: "Tên đăng nhập hoặc mật khẩu không đúng",
  text0007: "Đóng",
  text0008: "Ok",
  text0009: "Hủy",
  text0010: "Tạo tài khoản quản lý",
  text0011: "Xóa",
  text0012: "Cập nhật",
  text0013: "Ngày truy cập lần đầu",
  text0014: "Điểm",
  text0016: "Xếp hạng",
  text0017: "Trận đấu",
  text0018: "Dự đoán",
  text0019: "Kết quả trận đấu",
  text0020: "Thời gian ghi danh",
  text0022: "Bạn có muốn đăng xuất",
  text0023: "Không",
  text0024: "Quản lý tài khoản",
  text0025: "Lịch sử ticket",
  text0026: "Lưu",
  text0027: "Bạn có muốn xóa tài khoản này",
  text0028: "Thêm mới tài khoản",
  text0029: "Cập nhật tài khoản",
  text0030: "Xóa tài khoản thành công",
  text0031: "Tìm kiếm",
  text0032: "Từ ngày",
  text0033: "Đến ngày",
  text0034: "Quay lại",
  text0035: "Không có dữ liệu",
  text0036: "Tài khoản người dùng",
  text0037: "Tài khoản quản trị",
  text0038: "Chức vụ",
  text0039: "Đội thi đấu",
  text0040: "Xóa lựa chọn",
  text0041: "Tạo tài khoản người dùng",
  text0042: "Lịch sử minigame",
  text0043: "Thời gian tham gia",
  text0044: "Loại game",
  text0045: "Kết quả game",
  text0046: "Cầu thủ",
  text0047: "Dự đoán nhà vô địch",
  text0048: "Dự đoán cầu thủ xuất sắc",
  text0049: "Cập nhật kết quả trận đấu",
  text0050: "Xuất file excel",
  text0051: "Cài đặt hệ thống",
  text0052: "Cài đặt chung",
  text0053: "Ngày giờ bắt đầu event",
  text0054: "Ngày giờ kết thúc event",
  text0055: "Ngày bắt đầu lấy dữ liệu",
  text0056: "Ngày kết thúc lấy dữ liệu",
  text0057: "Bảo trì hệ thống",
  text0058: "Vòng cược ràng buộc",
  text0059: "Cài đặt thể lệ event",
  text0060: "Tổng nạp",
  text0061: "DTC Thể Thao",
  text0062: "Bạn có muốn cập nhập cài đặt hệ thống?",
  text0063: "Cập nhập cài đặt hệ thống thành công",
  text0064: "Quản lý giải đấu",
  text0065: "Tên giải đấu",
  text0066: "Hình ảnh",
  text0067: "Import dữ liệu",
  text0068: "Chọn file",
  text0069: "Tải lên",
  text0070: "Tạo dữ liệu",
  text0071: "Bạn có muốn xóa dữ liệu này?",
  text0072: "Dữ liệu đã xóa thành công",
  text0073: "Quản lý đội bóng",
  text0074: "Tên đội bóng",
  text0075: "Quản lý cầu thủ",
  text0076: "Tên cầu thủ",
  text0077: "Quản lý bảng đấu",
  text0078: "Tên bảng đấu",
  text0079: "BXH đội bóng",
  text0080: "TT",
  text0081: "Số trận",
  text0082: "Thắng",
  text0083: "Hòa",
  text0084: "Thua",
  text0085: "Điểm",
  text0086: "Cập nhật dữ liệu",
  text0087: "Quản lý trận đấu",
  text0088: "Quản lý đua top",
  text0089: "Điểm dự đoán",
  text0090: "Số trận dự đoán đúng liên tiếp",
  text0091: "Điểm dự đoán đúng liên tiếp",
  text0092: "Cập nhật điểm cho user fake",
  text0093: "Cập nhật kết quả",
  text0094: "Cập nhật kết quả minigame",
  text0095: "Giải đấu",
  text0096: "Nhà vô địch",
  text0097: "Cầu thủ xuất sắc",
  text0098: "Lịch sử kho",
  text0099: "Thời gian rút điểm",
  text0100: "Chi tiết lượt chơi",
  text0101: "Lượt chơi hiện tại",
  text0102: "Lượt chơi đã sử dụng",
  text0103: "Tổng lượt chơi",
  text0104: "Bù lượt chơi",
  text0105: "Lượt chơi bù",
  text0106: "Xác nhận bù {0} lượt chơi cho hội viên {1}",
  text0107: "Bù lượt chơi cho hội viên thành công",
  text0108: "Tháng - năm",
  text0109: "Tất cả",
  text0110: "Đua top bảng xếp hạng",
  text0111: "Chiến thần dự đoán",
  text0112: "Kẻ hủy diệt trận đấu",
  text0113: "Tổng giải thưởng đua top",
  text0114: "Giải thưởng",
  text0115: "Vòng đấu",
  text0116: "Kiểm tra lỗi",
  text0117: "Hành động",
  text0118: "Chi tiết",
  text0119: "Thời gian",
  text0120: "Lịch sử DTC",
  text0121: "DTC thể thao",
  text0122: "Tiền nạp",
  text0123: "Lịch sử chơi",
  text0124: "Lịch sử truy cập",
  text0125: "Trạng thái hiện tại lô đất",
  text0126: "Lịch sử chi tiết lô đất",
  text0127: "Ngày giờ truy cập",
  text0128: "Tổng số dưa đã thu hoạch",
  text0129: "Dưa đã bán",
  text0130: "Dưa đã còn lại",
  text0131: "Chi tiết kho HV",
  text0132: "Lịch sử bán",
  text0133: "Kiểu bán",
  text0134: "Số lượng",
  text0135: "Số điểm kiếm được",
  text0136: "Ngày giờ tạo kho",
  text0137: "Trạng thái bán",
  text0138: "Mô tả lỗi",
  text0139: "Thời gian bán",
  text0140: "Thời gian tạo",
  text0141: "Điều kiện lấy dữ liệu",
  text0142: "Chu kỳ",
  text0143: "Ngày bắt đầu",
  text0144: "Ngày kết thúc",
  text0145: "Tổng nạp",
  text0146: "Mức điểm quy đổi",
  text0147: "Bán lẻ",
  text0148: "Bán lô",
  text0149: "Bán vựa",
  text0150: "Chỉnh sửa text thể lệ",
  text0151: "Thể lệ",
  text0152: "Nội dung chương trình",
  text0153: "Cài đặt quyền hạn admin",
  text0154: "Ngày giờ HV cập nhật",
  text0155: "Ngày giờ hệ thống tự cập nhật",
  text_Url: "URL banner",
  ve_1_sao: "Vé 1 Sao",
  ve_2_sao: "Vé 2 Sao",
  ve_3_sao: "Vé 3 Sao",
  ve_3_sao_1: "Vé 3 Sao HV Ghi Danh",
  ve_3_sao_2: "Vé 3 Sao Trúng giải",
  turn: "Lượt",
  bonus_points: "Điểm tặng",
  play_process: "Trạng thái",
  score_submission_process: "Trạng thái Gửi điểm",
  desc: "Mô tả",
  number_of_prizes: "Tên giải",
  used_point: "Số coins sử dụng",
  Reward_Points: "Điểm thưởng",
  round: "Vòng Cược",
  status: "Tình Trạng",
  text_day: "Ngày",
  nature: "Tính chất",
  text_total: "Tổng nạp lúc ghi danh",
  text_fake: "Fake HV",
  text_prize: "Cài đặt giá trị thưởng",
  text_money: "Tiền thưởng",
  point: "Điểm thưởng",
  percent: "Tỷ lệ",
  add: "Add",
  remove: "Remove",
  number_round: "Vòng cược",
  update: "Cập Nhật",
  winner: "Người chiến thắng",
  setting_winner: "Thêm người chiến thắng",
  userName: "Tên người chiến thắng",
  hour: "Giờ tạo",
  minute: "Phút tạo",
  second: "Giây tạo",
  setting_rule: "Cài đặt thể lệ",
  precent_prize: "Thưởng",
  precent_round: "Vòng cược",
  title: "Title",
  message_1: "Message 1",
  message_2: "Message 2",
  list_user_fake: "Cài đặt User",
  Add_user_fake: "Thêm user",
  list_user: "List user",
  user_fake_name: "Tên User Fake",
  state_expired: "Trạng thái vé hết hạn",
  time_pzize: "Thời gian trả thưởng",
  time_play: "Thời gian chơi ",
  text_coins: "Số coins sử dụng",
  time_play_confirm: "Thời gian nhận điểm",
  text_delete_user: "Bạn chắc chắn muốn xóa user này?",
  text_modal_success: "Bạn cập nhật thành công!",
  ft: "Mã giao dịch",
  time_play_current: "Ngày giờ click chơi",
  text_code: "Mã giao dịch",
  expired: "Hạn sử dụng",
  create_day: "Thời gian tạo",
  create_person: "Người tạo",
  time_prize: "Thời gian mở thưởng",
  multiple: "Vòng cược",
};
