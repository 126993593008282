import { Button, Form, Input, Radio, Table, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { TEXT_DEF } from "../../constant/text";
import API, { HISTORY_STAR, Ticket3 } from "../../network/api";
import { UploadOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import SettingWinner from "../../components/setting-winner";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const ListUserFake = () => {
  const auth = JSON.parse(localStorage.getItem("auth"));
  const roleLocal = localStorage.getItem("roles");
  const roleArr = roleLocal?.split(",");

  const [form] = Form.useForm();

  const [indexTab, setIndexTab] = useState(0);
  const [dataList, setDataList] = useState();
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    getDataUserFake();
  }, []);

  useEffect(() => {
    if (
      auth?.role === "supper" ||
      (auth?.role === "admin" &&
        roleArr.some((el) => el === "tick_3_fake_edit"))
    ) {
      return setIndexTab(0);
    } else {
      return setIndexTab(1);
    }
  }, []);

  const handleChange = async ({ fileList: newFileList }) => {
    if (!newFileList[0].url && !newFileList[0].preview) {
      newFileList[0].preview = await getBase64(newFileList[0].originFileObj);
    }
    setFileList(newFileList);
  };

  const getDataUserFake = async () => {
    try {
      const res = await API.get(
        `${HISTORY_STAR.getFakeAll}?take=100&skip=1&order=DESC&keyword=null`
      );
      const data = res.data.data.result[0];
      setDataList(data);
    } catch (err) {
      console.log(err);
    }
  };

  const onChangeTab = (e) => {
    setIndexTab(e.target.value);
    getDataUserFake();
  };

  const onCreate = async () => {
    console.log(form.getFieldValue("userFake"));

    const newArray = form.getFieldValue("userFake").split(" ");
    console.log(newArray);
    const body = {
      usernames: newArray,
    };
    console.log(body);
    try {
      await API.post(`${Ticket3}/create-f-list`, body);
      form.resetFields();
    } catch (err) {
      console.log(err);
    }
  };

  const columnTable = [
    {
      title: TEXT_DEF.text0002,
      key: "username",
      dataIndex: "username",
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "username",
      render: (text, record) => {
        if (
          auth?.role === "supper" ||
          (auth?.role === "admin" &&
            roleArr.some((el) => el === "tick_3_fake_edit"))
        ) {
          return <Button danger onClick={() => handleDelete(record.id)}>Xóa</Button>;
        }
      },
    },
  ];

  const handleDelete = async (id) => {
    try {
      await API.delete(`${HISTORY_STAR.deleteTiket3}/${id}/f`);
      await getDataUserFake();
    } catch (err) {
      console.log(err);
    }
  };

  const uploadData = async () => {
    const formData = new FormData();
    formData.append("excel", fileList[0].originFileObj);

    await API.post(`${Ticket3}/create-f-list`, formData)
      .then((response) => {
        const result = response.data?.data?.result;
        if (result?.success) {
          setFileList([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <div className="col-12">
        {(auth?.role === "supper" || auth?.role === "admin") && (
          <div
            className="col-12"
            style={{
              borderBottom: "1px solid #CCC",
              paddingBottom: "2rem",
              marginBottom: "2rem",
            }}
          >
            <Radio.Group value={indexTab} onChange={onChangeTab}>
              {auth?.role === "supper" ||
                (auth?.role === "admin" &&
                  roleArr.some((el) => el === "tick_3_award_edit")) ? (
                <Radio.Button value={0}>{TEXT_DEF.setting_winner}</Radio.Button>
              ) : null}
              {auth?.role === "supper" ||
                (auth?.role === "admin" &&
                  roleArr.some((el) => el === "tick_3_fake_edit")) ? (
                <Radio.Button value={1}>{TEXT_DEF.Add_user_fake}</Radio.Button>
              ) : null}
              {auth?.role === "supper" ||
                (auth?.role === "admin" &&
                  roleArr.some(
                    (el) => el === "tick_3_fake_edit" || el === "tick_3_fake_view"
                  )) ? (
                <Radio.Button value={2}>{TEXT_DEF.list_user}</Radio.Button>
              ) : null}
            </Radio.Group>
          </div>
        )}
      </div>
      {indexTab === 0 && <SettingWinner />}
      {indexTab === 1 && (
        <div className="col-12">
          <h1
            className=""
            style={{
              color: "#777",
            }}
          >
            Thêm User Fake
          </h1>
          {/* <div className="col-6 gap-2" style={{ display: "flex" }}>
            <div className="col-3">Thêm User bằng file: </div>
            <Upload
              //   fileList={fileList}
              onChange={(fileList) => handleChange(fileList)}
              //   onRemove={handleRemove}
              beforeUpload="/"
            >
              <Button
                type="primary"
                style={{ display: "flex", alignItems: "center" }}
                icon={<UploadOutlined />}
                onClick={uploadData}
              >
                Import
              </Button>
            </Upload>
            <div className="col-2">
              <Button onClick={uploadData} type="primary">
                xác nhận
              </Button>
            </div>
          </div> */}
          <div
            className="col-12 mt-5"
            style={{ display: "flex", justifyContent: "start" }}
          >
            <Form
              className="col-12"
              form={form}
              layout="inline"
              autoComplete="off"
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "1rem",
              }}
              onFinish={onCreate}
            >
              <div className="col-2">{TEXT_DEF.user_fake_name}: </div>
              <Form.Item
                name="userFake"
                rules={[
                  {
                    required: true,
                    message: TEXT_DEF.text0004,
                  },
                ]}
                style={{ marginTop: "10px", marginBottom: "20px" }}
              >
                <Input style={{ width: "50%" }} rows={6} />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Thêm User
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      )}
      {indexTab === 2 && (
        <div
          className="col-12"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "80%",
              alignItems: "center",
              marginTop: 20,
            }}
          >
            <Table
              key="datauser"
              columns={columnTable}
              dataSource={dataList}
              pagination={true}
              bordered
              style={{ width: "100%" }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ListUserFake;
