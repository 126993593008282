export const PERMISSION = [
  {
    id: 0,
    value: "user_view",
    label: "Xem hội viên",
  },
  {
    id: 1,
    value: "user_edit",
    label: "Tạo admin",
  },
  {
    id: 2,
    value: "tick_1_view",
    label: "Xem hệ thống tiket 1",
  },
  {
    id: 3,
    value: "tick_1_edit",
    label: "Cài đặt hệ thống tiket 1",
  },
  {
    id: 4,
    value: "tick_2_view",
    label: "Xem hệ thống tiket 2",
  },
  {
    id: 5,
    value: "tick_2_edit",
    label: "Cài đặt hệ thống tiket 2",
  },

  {
    id: 14,
    value: "tick_3_checkin_view",
    label: "Xem lịch sử ticket 3",
  },
  {
    id: 15,
    value: "tick_3_checkin_edit",
    label: "Cài đặt ticket 3",
  },
  {
    id: 17,
    value: "tick_3_fake_view",
    label: "Xem list user fake ticket 3",
  },
  {
    id: 20,
    value: "tick_3_fake_edit",
    label: "Cài đặt user fake",
  },

  {
    id: 18,
    value: "tick_3_award_view",
    label: "Xem user trúng giải ticket 3",
  },
  {
    id: 19,
    value: "tick_3_award_edit",
    label: "Cài đặt người chiến thắng",
  },
  {
    id: 10,
    value: "system_config_view",
    label: "Xem text thể lệ",
  },
  {
    id: 11,
    value: "system_config_edit",
    label: "Cập nhật text thể lệ",
  },
  {
    id: 12,
    value: "admin_permissions_view",
    label: "Xem quyền hạn của admin",
  },
  {
    id: 13,
    value: "admin_permissions_edit",
    label: "Cập nhật quyền hạn của admin",
  },
];
