import { Radio } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { TEXT_DEF } from "../../constant/text";
import FormRule from "../../components/formRule";
import API, { COMMON } from "../../network/api";
import ModalCommon from "../../components/Modal";

const SettingRule = () => {
  const auth = JSON.parse(localStorage.getItem("auth"));
  const roleLocal = localStorage.getItem("roles");
  const roleArr = roleLocal?.split(",");

  const [indexTab, setIndexTab] = useState(0);
  const [textRuleTicket1, setTextRuleTicket1] = useState();
  const [textRuleTicket2, setTextRuleTicket2] = useState();
  const [textRuleTicket3, setTextRuleTicket3] = useState();
  const [isShowModalConfirm, setIsShowModalConfirm] = useState(false);
  const [isShowModalSuccess, setIsShowModalSuccess] = useState(false);
  const [keyword, setKeyWord] = useState();

  useEffect(() => {
    getDataRuleOneStar();
    getDataTicketTowStar();
    getDataTicketThreeStar();
  }, []);

  const getDataRuleOneStar = async () => {
    try {
      const res = await API.get(
        `${COMMON}/all?take=10&skip=1&order=DESC&keyword=%7B%22module%22%3A%22TICKET1%22%2C%22item%22%3A%22ticket_1_award_congul%22%7D`
      );
      const data = res.data.data.result[0];
      if (res.data.message && data) {
        setTextRuleTicket1(data[0]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getDataTicketTowStar = async () => {
    try {
      const kw = encodeURIComponent(
        JSON.stringify({ module: "TICKET2", item: "ticket_2_award_congul" })
      );
      const res = await API.get(
        `${COMMON}/all?take=10&skip=1&order=DESC&keyword=${kw}`
      );
      const data = res.data.data.result[0];
      if (res.data.message && data) {
        setTextRuleTicket2(data[0]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getDataTicketThreeStar = async () => {
    try {
      const kw = encodeURIComponent(
        JSON.stringify({ module: "TICKET3", item: "ticket_3_award_congul" })
      );
      const res = await API.get(
        `${COMMON}/all?take=10&skip=1&order=DESC&keyword=${kw}`
      );
      const data = res.data.data.result[0];
      if (res.data.message && data) {
        setTextRuleTicket3(data[0]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleUpdate = () => {
    setIsShowModalConfirm(false);
    setIsShowModalSuccess(true);
    console.log(1111);
    switch (keyword) {
      case "ticket1":
        return handleUpdateRuleOneStar();
      case "ticket2":
        return handleUpdateRuleTowStar();
      case "ticket3":
        return handleUpdateRuleThreeStar();
    }
  };

  const handleUpdateRuleOneStar = async () => {
    try {
      await API.patch(`${COMMON}/${textRuleTicket1.id}`, {
        value: textRuleTicket1.value,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleUpdateRuleTowStar = async () => {
    try {
      try {
        await API.patch(`${COMMON}/${textRuleTicket2.id}`, {
          value: textRuleTicket2.value,
        });
      } catch (err) {
        console.log(err);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleUpdateRuleThreeStar = async () => {
    try {
      try {
        await API.patch(`${COMMON}/${textRuleTicket3.id}`, {
          value: textRuleTicket3.value,
        });
      } catch (err) {
        console.log(err);
      }
    } catch (err) {
      console.log(err);
    }
  };

  console.log(keyword);

  const handleShowModalConfirm = useCallback((e, key) => {
    setIsShowModalConfirm(true);
    setKeyWord(key);
  }, []);

  return (
    <div className="col-12">
      <div
        className="col-12"
        style={{
          borderBottom: "1px solid #CCC",
          paddingBottom: "2rem",
          marginBottom: "2rem",
        }}
      >
        <Radio.Group
          value={indexTab}
          onChange={(e) => setIndexTab(e.target.value)}
        >
          <Radio.Button value={0}>{TEXT_DEF.ve_1_sao}</Radio.Button>
          <Radio.Button value={1}>{TEXT_DEF.ve_2_sao}</Radio.Button>
          <Radio.Button value={2}>{TEXT_DEF.ve_3_sao}</Radio.Button>
        </Radio.Group>
      </div>
      {indexTab === 0 ? (
        <FormRule
          onHandleChangeInput={(e) =>
            setTextRuleTicket1({ ...textRuleTicket1, value: e.target.value })
          }
          auth={auth}
          textRule={textRuleTicket1?.value}
          roleArr={roleArr}
          onShowModalConfirm={(e) => handleShowModalConfirm(e, "ticket1")}
        />
      ) : indexTab === 1 ? (
        <FormRule
          onHandleChangeInput={(e) =>
            setTextRuleTicket2({ ...textRuleTicket2, value: e.target.value })
          }
          auth={auth}
          textRule={textRuleTicket2?.value}
          roleArr={roleArr}
          onShowModalConfirm={(e) => handleShowModalConfirm(e, "ticket2")}
        />
      ) : indexTab === 2 ? (
        <FormRule
          onHandleChangeInput={(e) =>
            setTextRuleTicket3({ ...textRuleTicket3, value: e.target.value })
          }
          auth={auth}
          textRule={textRuleTicket3?.value}
          roleArr={roleArr}
          onShowModalConfirm={(e) => handleShowModalConfirm(e, "ticket3")}
        />
      ) : null}
      {isShowModalConfirm && (
        <ModalCommon
          isShow={isShowModalConfirm}
          message={TEXT_DEF.text0062}
          txtBtnAcc={TEXT_DEF.text0008}
          textBtnClose={TEXT_DEF.text0023}
          actionNext={handleUpdate}
          close={() => setIsShowModalConfirm(false)}
        />
      )}
      {isShowModalSuccess && (
        <ModalCommon
          isShow={isShowModalSuccess}
          message={TEXT_DEF.text0063}
          txtBtnAcc={TEXT_DEF.text0007}
          actionNext={() => {
            setIsShowModalSuccess(false);
          }}
          close={() => {
            setIsShowModalSuccess(false);
          }}
        />
      )}
    </div>
  );
};

export default SettingRule;
