import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import { Form, Input, Button, Select, Checkbox, Col } from "antd";
import API, { USER } from "../../network/api";
import { TEXT_DEF } from "../../constant/text";
import { PERMISSION } from "../../constant/pesmission";
const { Option } = Select;

const SetPermission = () => {
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const [listRole, setListRole] = useState([]);
  const navigate = useNavigate();
  const indexTabLocal = localStorage.getItem("indexLocalCreate") || 2;
  const [errorMess, setErrorMess] = useState();
  const auth = JSON.parse(localStorage.getItem("auth"));
  const roleLocal = localStorage.getItem("roles");
  const roleArr = roleLocal?.split(",");
  const { id } = useParams();
  const [permissionDf, setPermissionDf] = useState();
  const [permissionDfArr, setPermissionDfArr] = useState();
  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    const getUserDetail = async () => {
      await API.get(`${USER}/${id}`).then((response) => {
        const result = response?.data?.data?.result;
        console.log(result);
        if (result) {
          form.setFieldValue("username", result?.username);
          const permisssons = result?.option;
          setPermissionDf(permisssons);
          setPermissionDfArr(permisssons.split(","));
          form.setFieldValue("permission", permisssons.split(","));
        }
      });
    };

    getUserDetail();
  }, []);

  useEffect(() => {
    let roleLocal = ["supper", "admin"];
    let roles = [];

    if (auth === "supper") {
      roleLocal = ["supper", "admin"];
      const newRl0 = {
        id: 0,
        value: roleLocal[0],
        label: roleLocal[0],
      };
      roles.push(newRl0);
      const newRl1 = {
        id: 1,
        value: roleLocal[1],
        label: roleLocal[1],
      };
      roles.push(newRl1);
    } else {
      roleLocal = ["admin"];
      const newRl0 = {
        id: 0,
        value: roleLocal[0],
        label: roleLocal[0],
      };
      roles.push(newRl0);
    }

    setListRole(roles);
  }, []);

  useEffect(() => {
    forceUpdate({});
  }, []);

  const onChangeCheck = (checkedValues) => {
    setSelectedItems(checkedValues);
  };

  const onUpdatePermission = async () => {
    const params = {
      permission: form.getFieldValue("permission"),
    };

    try {
      await API.patch(`${USER}/${id}/permission`, params).then((response) => {
        if (response.data.data.code === 20004) {
          localStorage.setItem("indexTabAcc", indexTabLocal);
          navigate("/account");
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  const backScreen = () => {
    localStorage.setItem("indexTabAcc", "2");
    navigate("/account");
  };

  const renderFormByAdmin = () => {
    return (
      <Form
        className="form-create"
        form={form}
        layout="inline"
        autoComplete="off"
        onFinish={onUpdatePermission}
      >
        <div className="col-8">
          <div
            className="col-12"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="col-3">{TEXT_DEF.text0002}</div>
            <div className="col-9">
              <Form.Item name="username" style={{ marginRight: 0 }}>
                <Input maxLength={255} readOnly={true} />
              </Form.Item>
            </div>
          </div>

          <div style={{ marginBottom: 15 }} />

          <div
            className="col-12"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="col-3">
              {TEXT_DEF.text0038} <span style={{ color: "red" }}>*</span>
            </div>
            <div className="col-9">
              <Form.Item
                name="permission"
                rules={[
                  {
                    required: true,
                    message: TEXT_DEF.text0004,
                  },
                ]}
                style={{ marginRight: 0 }}
              >
                {/* <Select
                  mode="multiple"
                  value={selectedItems}
                  onChange={setSelectedItems}
                >
                  {PERMISSION.map((item) => (
                    <Option key={item.id} value={item.value}>
                      {item.label}
                    </Option>
                  ))}
                </Select> */}
                <Checkbox.Group
                  style={{ width: "100%" }}
                  onChange={onChangeCheck}
                >
                  {PERMISSION.map((item, index) => (
                    <Col span={12} key={index}>
                      <Checkbox value={item?.value}>{item?.label}</Checkbox>
                    </Col>
                  ))}
                </Checkbox.Group>
              </Form.Item>
            </div>
          </div>

          <div style={{ marginBottom: 30 }} />

          {auth?.role === "supper" ||
          roleArr?.indexOf("admin_permissions_edit") > -1 ? (
            <Form.Item shouldUpdate style={{ width: "100%" }}>
              {() => (
                <div className="lg-btn">
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={
                      !!form
                        .getFieldsError()
                        .filter(({ errors }) => errors.length).length
                    }
                  >
                    {TEXT_DEF.text0026}
                  </Button>
                </div>
              )}
            </Form.Item>
          ) : null}
        </div>
      </Form>
    );
  };

  return (
    <div className="col-12">
      <Button
        type="default"
        htmlType="button"
        style={{ marginBottom: 30 }}
        onClick={backScreen}
      >
        {TEXT_DEF.text0034}
      </Button>
      {renderFormByAdmin()}
    </div>
  );
};

export default SetPermission;
