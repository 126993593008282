import React, { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import SideBarCustom from "../../components/Sidebar";
import HeaderCustom from "../../components/Header";
import Login from "../login";
import AccountList from "../account/list";
import CreateAccount from "../account/create";
import GrowHistory from "../growHis/list";
import SettingSys from "../settingSys";
import { Layout } from "antd";
import Logs from "../logs/list";
import SetPermission from "../account/setPermission";
import SettingRule from "../settingRule";
import ListUserFake from "../list-user-fake";

const { Sider, Content } = Layout;

const Main = () => {
  const auth = localStorage.getItem("auth");
  const [collapsed, setCollapsed] = useState(false);

  const renderPublic = () => {
    return (
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/login" element={<Login />} />
      </Routes>
    );
  };

  const renderContent = () => {
    return (
      <Layout>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          className="site-body"
        >
          <SideBarCustom />
        </Sider>
        <Layout className="site-layout">
          <HeaderCustom
            collapsed={collapsed}
            updateCollapsed={() => setCollapsed(!collapsed)}
            username={JSON.parse(auth).username}
          />
          <Content
            className="site-layout-background"
            style={{
              margin: "24px 16px",
              padding: 24,
            }}
          >
            <Routes>
              <Route path="/" element={<Navigate to="/account" />} />
              <Route path="/account" element={<AccountList />} />
              <Route path="/createAccount" element={<CreateAccount />} />
              <Route path="/setPermission/:id" element={<SetPermission />} />
              <Route path="/growHis" element={<GrowHistory />} />
              {/* <Route path='/warehouseHis' element={<WarehouseHistory />} /> */}
              <Route path="/log" element={<Logs />} />
              <Route path="/settingSys" element={<SettingSys />} />
              {/* <Route path="/setting-winner" element={<SettingWinner />} /> */}
              <Route path="/setting-rule" element={<SettingRule />} />
              <Route path="/list-user-fake" element={<ListUserFake />} />
            </Routes>
          </Content>
        </Layout>
      </Layout>
    );
  };

  return <>{auth ? renderContent() : renderPublic()}</>;
};

export default Main;
