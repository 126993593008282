import { Button, InputNumber } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";

import { TEXT_DEF } from "../../constant/text";

const FormRule = ({
  textRule,
  auth,
  roleArr,
  onHandleChangeInput,
  onShowModalConfirm,
}) => {
  return (
    <>
      <div className="col-12">
        <div
          className="col-12"
          style={{ display: "flex", marginTop: 30, alignItems: "center" }}
        >
          <div className="col-9">
            <div
              className="col-12"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div className="col-4">{TEXT_DEF.text0151}:</div>
              <div className="col-8" style={{ display: "flex" }}>
                <TextArea
                  name="textRule"
                  style={{ width: "100%" }}
                  value={textRule}
                  onChange={(e) => onHandleChangeInput(e)}
                  rows={20}
                  spellCheck="false"
                  autoComplete="off"
                />
              </div>
            </div>
          </div>
          {auth?.role === "supper" ||
          (auth?.role === "admin" &&
            roleArr?.indexOf("system_config_edit") > -1) ? (
            <div
              className="col-3"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                htmlType="submit"
                type="primary"
                onClick={(e) => onShowModalConfirm(e)}
              >
                {TEXT_DEF.text0012}
              </Button>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default FormRule;
