import React, { memo, useState } from "react";
import { LIST_CYCLE } from "../../../constant/generate";
import { TEXT_DEF } from "../../../constant/text";
import { Button, InputNumber, Switch } from "antd";

const TicketOne = ({
  timeCycle,
  multipleTiket,
  pointDraw,
  checkInto,
  pointMax,
  checkInForm,
  pointMin,
  maintenance,
  onSetTimeCycle,
  onShowModalConfirm,
  onSetMultipleTiket,
  onSetPointDraw,
  onSetCheckInTo,
  onSetPointMax,
  onSetCheckInForm,
  onSetPointMin,
  onHandleUpdateMaintenance,
  setMaintenance,
  onShowModal,
}) => {
  return (
    <>
      <div className="col-12" style={{ display: "flex", alignItems: "center" }}>
        <div className="col-4">{TEXT_DEF.text0057}:</div>
        <div className="col-8" style={{ display: "flex" }}>
          <Switch
            checkedChildren="Bật"
            unCheckedChildren="Tắt"
            checked={maintenance?.value}
            style={{ width: 60, marginTop: "5px" }}
            onChange={(e) =>
              setMaintenance((prev) => ({
                ...prev,
                value: e,
              }))
            }
          />
          <Button
            type="primary"
            onClick={(e) => onShowModal(e, "mainticket1")}
            style={{
              marginLeft: "2rem",
            }}
          >
            {TEXT_DEF.update}
          </Button>
        </div>
      </div>
      <div className="col-12 mt-5">
        <div
          className="col-12"
          style={{ display: "flex", marginTop: 30, alignItems: "center" }}
        >
          <div className="col-12">
            <div
              className="col-12"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div className="col-2">{TEXT_DEF.text0142}:</div>
              <div
                className="col-10"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div className="col-10" style={{ display: "flex" }}>
                  <span className="col-4">{LIST_CYCLE[timeCycle?.item]}</span>
                  <div className="col-4">
                    <InputNumber
                      name="timeCycle"
                      min={0}
                      max={99999999}
                      style={{ width: "65%", marginLeft: "1rem" }}
                      value={timeCycle?.value}
                      onChange={(e) =>
                        onSetTimeCycle((prev) => ({
                          ...prev,
                          value: e,
                        }))
                      }
                    />
                  </div>
                  <div
                    className="col-2"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Button
                      htmlType="submit"
                      type="primary"
                      onClick={(e) => onShowModalConfirm(e, "timeCycle")}
                    >
                      {TEXT_DEF.text0012}
                    </Button>
                  </div>
                </div>
                <div className="col-10 mt-5" style={{ display: "flex" }}>
                  <span className="col-4">
                    {LIST_CYCLE[multipleTiket?.item]}
                  </span>
                  <div className="col-4">
                    <InputNumber
                      name="multipleTiket"
                      min={0}
                      max={99999999}
                      style={{ width: "65%", marginLeft: "1rem" }}
                      value={multipleTiket?.value}
                      onChange={(e) =>
                        onSetMultipleTiket((prev) => ({
                          ...prev,
                          value: e,
                        }))
                      }
                    />
                  </div>
                  <div
                    className="col-2"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Button
                      htmlType="submit"
                      type="primary"
                      onClick={(e) => onShowModalConfirm(e, "multipleTiket")}
                    >
                      {TEXT_DEF.text0012}
                    </Button>
                  </div>
                </div>
                <div className="col-10 mt-5" style={{ display: "flex" }}>
                  <span className="col-4">{LIST_CYCLE[pointDraw?.item]}</span>
                  <div className="col-4">
                    <InputNumber
                      name="pointDraw"
                      min={0}
                      max={99999999}
                      style={{ width: "65%", marginLeft: "1rem" }}
                      value={pointDraw?.value}
                      onChange={(e) =>
                        onSetPointDraw((prev) => ({
                          ...prev,
                          value: e,
                        }))
                      }
                    />
                  </div>
                  <div
                    className="col-2"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Button
                      htmlType="submit"
                      type="primary"
                      onClick={(e) => onShowModalConfirm(e, "pointDraw")}
                    >
                      {TEXT_DEF.text0012}
                    </Button>
                  </div>
                </div>
                <div className="col-10 mt-5" style={{ display: "flex" }}>
                  <span className="col-4">{LIST_CYCLE[checkInForm?.item]}</span>
                  <div className="col-4">
                    <InputNumber
                      name="checkInForm"
                      min={0}
                      max={99999999}
                      style={{ width: "65%", marginLeft: "1rem" }}
                      value={checkInForm?.value}
                      onChange={(e) =>
                        onSetCheckInForm((prev) => ({
                          ...prev,
                          value: e,
                        }))
                      }
                    />
                  </div>
                  <div
                    className="col-2"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Button
                      htmlType="submit"
                      type="primary"
                      onClick={(e) => onShowModalConfirm(e, "checkInForm")}
                    >
                      {TEXT_DEF.text0012}
                    </Button>
                  </div>
                </div>
                <div className="col-10 mt-5" style={{ display: "flex" }}>
                  <span className="col-4">{LIST_CYCLE[checkInto?.item]}</span>
                  <div className="col-4">
                    <InputNumber
                      name="checkInto"
                      min={0}
                      max={99999999}
                      style={{ width: "65%", marginLeft: "1rem" }}
                      value={checkInto?.value}
                      onChange={(e) =>
                        onSetCheckInTo((prev) => ({
                          ...prev,
                          value: e,
                        }))
                      }
                    />
                  </div>
                  <div
                    className="col-2"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Button
                      htmlType="submit"
                      type="primary"
                      onClick={(e) => onShowModalConfirm(e, "checkInto")}
                    >
                      {TEXT_DEF.text0012}
                    </Button>
                  </div>
                </div>

                <div className="col-10 mt-5" style={{ display: "flex" }}>
                  <span className="col-4">{LIST_CYCLE[pointMax?.item]}</span>
                  <div className="col-4">
                    <InputNumber
                      name="pointMax"
                      min={0}
                      max={99999999}
                      style={{ width: "65%", marginLeft: "1rem" }}
                      value={pointMax?.value}
                      onChange={(e) =>
                        onSetPointMax((prev) => ({
                          ...prev,
                          value: e,
                        }))
                      }
                    />
                  </div>
                  <div
                    className="col-2"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Button
                      htmlType="submit"
                      type="primary"
                      onClick={(e) => onShowModalConfirm(e, "pointMax")}
                    >
                      {TEXT_DEF.text0012}
                    </Button>
                  </div>
                </div>

                <div className="col-10 mt-5" style={{ display: "flex" }}>
                  <span className="col-4">{LIST_CYCLE[pointMin?.item]}</span>
                  <div className="col-4">
                    <InputNumber
                      name="pointMin"
                      min={0}
                      max={99999999}
                      style={{ width: "65%", marginLeft: "1rem" }}
                      value={pointMin?.value}
                      onChange={(e) =>
                        onSetPointMin((prev) => ({
                          ...prev,
                          value: e,
                        }))
                      }
                    />
                  </div>
                  <div
                    className="col-2"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Button
                      htmlType="submit"
                      type="primary"
                      onClick={(e) => onShowModalConfirm(e, "pointMin")}
                    >
                      {TEXT_DEF.text0012}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(TicketOne);
