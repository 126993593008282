import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Form,
  Input,
  Button,
  DatePicker,
  Table,
  Pagination,
  Spin,
  Radio,
} from "antd";
import API, { HISTORY_STAR } from "../../network/api";
import moment from "moment";
import "moment-timezone";
import { downloadExcel } from "react-export-table-to-excel";
import { TEXT_DEF } from "../../constant/text";
import { HISTORY_STATUS } from "../../constant/historry";
import { PERMISSION_HIS_3 } from "../../constant/generate";

const GrowHistory = () => {
  const auth = JSON.parse(localStorage.getItem("auth"));
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const navigate = useNavigate();
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [dataFilterTab1, setDataFilterTab1] = useState();
  const [dataFilterTab2, setDataFilterTab2] = useState();
  const [dataList, setDataList] = useState([]);
  const [pageTab1, setPageTab1] = useState(1);
  const [totalRecordTab1, setTotalRecordTab1] = useState(0);
  const [pageTab2, setPageTab2] = useState(1);
  const [totalRecordTab2, setTotalRecordTab2] = useState(0);
  const [pageTab3, setPageTab3] = useState(1);
  const [totalRecordTab3, setTotalRecordTab3] = useState(0);
  const [loading, setLoading] = useState(false);
  const [indexTab, setIndexTab] = useState(0);
  const [idLandSl, setIdLandSl] = useState();

  const roleLocal = localStorage.getItem("roles");
  const roleArr = roleLocal?.split(",");

  const [dataFilterTab4, setDataFilterTab4] = useState();
  const [pageTab4, setPageTab4] = useState(1);
  const [totalRecordTab4, setTotalRecordTab4] = useState(0);

  const [dataFilterTab3, setDataFilterTab3] = useState();

  const locale = {
    emptyText: TEXT_DEF.text0035,
  };

  useEffect(() => {
    if (
      auth?.role === "supper" ||
      (auth?.role === "admin" &&
        roleArr.some((el) => el === "tick_1_view" || el === "tick_1_edit"))
    ) {
      return setIndexTab(0);
    }
    if (
      auth?.role === "supper" ||
      (auth?.role === "admin" &&
        roleArr.some((el) => el === "tick_2_view" || el === "tick_2_view"))
    ) {
      return setIndexTab(1);
    }
    if (
      auth?.role === "supper" ||
      (auth?.role === "admin" &&
        PERMISSION_HIS_3.some((el) => roleArr.includes(el)))
    ) {
      return setIndexTab(2);
    }
  }, []);

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    if (indexTab === 0) {
      getDataTab1();
    }
  }, [indexTab, pageTab1, dataFilterTab1]);

  useEffect(() => {
    if (indexTab === 1) {
      getDataTab2();
    }
  }, [indexTab, pageTab2, dataFilterTab2]);

  useEffect(() => {
    if (indexTab === 2) {
      getDataTab3();
    }
  }, [indexTab, pageTab3, dataFilterTab3]);

  useEffect(() => {
    if (indexTab === 3) {
      getDataTab4();
    }
  }, [indexTab, pageTab4, dataFilterTab4]);

  const onChangeTab = (e) => {
    setIndexTab(e.target.value);
    setPageTab1(1);
    setPageTab2(1);
    setPageTab3(1);
    setDateFrom(null);
    setDateTo(null);
    form.setFieldValue("usernameFilter", undefined);
    form.setFieldValue("dateFrom", undefined);
    form.setFieldValue("dateTo", undefined);
    setIdLandSl(undefined);
  };

  const getDataTab1 = async () => {
    setLoading(true);
    let kw = dataFilterTab1
      ? encodeURIComponent(JSON.stringify(dataFilterTab1))
      : null;

    let URL = `${HISTORY_STAR.getOneStar}?take=10&skip=${pageTab1}&order=DESC&keyword=${kw}`;
    const listData = await API.get(URL);

    const result = listData.data?.data?.result[0];
    if (result?.length > 0) {
      let dataCV = [];
      result?.map((item) => {
        let itemNew = {
          key: item?.id,
          username: item?.username || null,
          pickedAt: item.pickedAt
            ? moment(item?.pickedAt).utcOffset(7).format("DD-MM-YYYY HH:mm:ss")
            : null,
          confirmedAt: item.confirmedAt
            ? moment(item?.confirmedAt)
              .utcOffset(7)
              .format("DD-MM-YYYY HH:mm:ss")
            : null,
          point: item?.point,
          turnIndex: item?.turnIndex,
          status: HISTORY_STATUS[item?.status],
          isExpired: item?.isExpired === false ? "Chưa hết hạn" : "Đã hết hạn",
          sendStatus: item?.sendStatus,
          // description: item?.description,
        };

        dataCV.push(itemNew);
      });
      setDataList(dataCV);
      setTotalRecordTab1(listData.data?.data?.result[1]);
    } else {
      setDataList([]);
    }
    setLoading(false);
  };

  const exportDataTab1 = async () => {
    setLoading(true);
    let kw = dataFilterTab1
      ? encodeURIComponent(JSON.stringify(dataFilterTab1))
      : null;

    let URL = `${HISTORY_STAR.getOneStar}?take=1000000&skip=${pageTab1}&order=DESC&keyword=${kw}`;
    const listData = await API.get(URL);

    const result = listData.data?.data?.result[0];
    if (result?.length > 0) {
      let dataCV = [];
      result?.map((item) => {
        let itemNew = {
          username: item?.username || null,
          pickedAt: item.pickedAt
            ? moment(item?.pickedAt).utcOffset(7).format("DD-MM-YYYY HH:mm:ss")
            : null,
          confirmedAt: item.confirmedAt
            ? moment(item?.confirmedAt)
              .utcOffset(7)
              .format("DD-MM-YYYY HH:mm:ss")
            : null,
          point: item?.point,
          turnIndex: item?.turnIndex,
          status: HISTORY_STATUS[item?.status],
          isExpired: item?.isExpired === false ? "Chưa hết hạn" : "Đã hết hạn",
          sendStatus: item?.sendStatus,
          // description: item?.description,
        };
        dataCV.push(itemNew);
      });
      const header = [
        TEXT_DEF.text0002,
        TEXT_DEF.time_play,
        TEXT_DEF.time_play_confirm,
        TEXT_DEF.bonus_points,
        TEXT_DEF.turn,
        TEXT_DEF.play_process,
        TEXT_DEF.expired,
        TEXT_DEF.score_submission_process,
        // TEXT_DEF.desc,
      ];

      downloadExcel({
        fileName: "Lịch sử chơi vé 1 sao",
        sheet: "Lịch sử truy cập",
        tablePayload: {
          header: header,
          body: dataCV,
        },
      });
    }
    setLoading(false);
  };

  const getDataTab2 = async () => {
    setLoading(true);
    let kw = dataFilterTab2
      ? encodeURIComponent(JSON.stringify(dataFilterTab2))
      : null;

    let URL = `${HISTORY_STAR.getTowStar}?take=10&skip=${pageTab2}&order=DESC&keyword=${kw}`;
    const listData = await API.get(URL);
    const result = listData.data?.data?.result[0];
    if (result?.length > 0) {
      let dataCV = [];
      result?.map((item) => {
        let itemNew = {
          key: item.id,
          username: item?.username,
          ft: item?.ft,
          pointStatus: HISTORY_STATUS[item?.status],
          createdAt: moment(item?.createdAt)
            .utcOffset(7)
            .format("DD-MM-YYYY HH:mm:ss"),
          confirmedAt: item.confirmedAt
            ? moment(item?.confirmedAt)
              .utcOffset(7)
              .format("DD-MM-YYYY HH:mm:ss")
            : null,
          awardTitle: item?.awardTitle,
          coin: +item?.coin,
          point: +item?.point,
          multiple: item?.multiple,
        };
        dataCV.push(itemNew);
      });
      setDataList(dataCV);
      setTotalRecordTab2(listData.data?.data?.result[1]);
    } else {
      setDataList([]);
    }
    setLoading(false);
  };

  const exportDataTab2 = async () => {
    setLoading(true);
    let kw = dataFilterTab2
      ? encodeURIComponent(JSON.stringify(dataFilterTab2))
      : null;
    let URL = `${HISTORY_STAR.getTowStar}?take=10000&skip=${pageTab2}&order=DESC&keyword=${kw}`;
    const listData = await API.get(URL);
    const result = listData.data?.data?.result[0];
    if (result?.length > 0) {
      let dataCV = [];
      result?.map((item) => {
        let itemNew = {
          username: item?.username,
          ft: item?.ft,

          createdAt: item.createdAt
            ? moment(item?.createdAt).utcOffset(7).format("DD-MM-YYYY HH:mm:ss")
            : null,
          confirmedAt: item.confirmedAt
            ? moment(item?.confirmedAt)
              .utcOffset(7)
              .format("DD-MM-YYYY HH:mm:ss")
            : null,
          awardTitle: item?.awardTitle,
          coin: +item?.coin,
          pointStatus: HISTORY_STATUS[item?.status],
          point: +item?.point,
          multiple: item?.multiple,
        };
        dataCV.push(itemNew);
      });
      const header = [
        TEXT_DEF.text0002,
        TEXT_DEF.ft,
        TEXT_DEF.time_play,
        TEXT_DEF.time_play_confirm,
        TEXT_DEF.number_of_prizes,
        TEXT_DEF.used_point,
        TEXT_DEF.play_process,
        TEXT_DEF.Reward_Points,
        TEXT_DEF.round,
      ];
      downloadExcel({
        fileName: "Lịch sử chơi vé 2 sao ",
        sheet: "sheet 1",
        tablePayload: {
          header: header,
          body: dataCV,
        },
      });
    }
    setLoading(false);
  };

  const getDataTab3 = async () => {
    setLoading(true);
    let kw = dataFilterTab3
      ? encodeURIComponent(JSON.stringify(dataFilterTab3))
      : null;
    let URL = `${HISTORY_STAR.getThreeCheckIn}?take=10&skip=${pageTab3}&order=DESC&keyword=${kw}`;
    const listData = await API.get(URL);

    const result = listData.data?.data?.result[0];

    if (result?.length > 0) {
      let dataCV = [];
      result?.map((item, index) => {
        let itemNew = {
          key: index,
          username: item?.username,
          createdAt: moment(item?.createdAt)
            .utcOffset(7)
            .format("DD-MM-YYYY HH:mm:ss"),
          deposit: item?.deposit,
        };
        dataCV.push(itemNew);
      });
      setDataList(dataCV);
      setTotalRecordTab3(listData.data?.data?.result[1]);
    } else {
      setDataList([]);
      setTotalRecordTab3();
    }
    setLoading(false);
  };

  const exportDataTab3 = async () => {
    setLoading(true);
    let kw = dataFilterTab3
      ? encodeURIComponent(JSON.stringify(dataFilterTab2))
      : null;
    let URL = `${HISTORY_STAR.getThreeCheckIn}?take=10000&skip=${pageTab3}&order=DESC&keyword=${kw}`;
    const listData = await API.get(URL);
    const result = listData.data?.data?.result[0];
    if (result?.length > 0) {
      let dataCV = [];
      result?.map((item) => {
        let itemNew = {
          username: item?.username,
          createdAt: moment(item?.createdAt)
            .utcOffset(7)
            .format("DD-MM-YYYY HH:mm:ss"),
          deposit: item?.deposit,
        };
        dataCV.push(itemNew);
      });
      const header = [
        TEXT_DEF.text0002,
        TEXT_DEF.text0020,
        TEXT_DEF.text_total,
      ];
      downloadExcel({
        fileName: "lịch sử vé 3 sao HV ghi danh",
        sheet: "Sheets 1",
        tablePayload: {
          header: header,
          body: dataCV,
        },
      });
    }
    setLoading(false);
  };

  const getDataTab4 = async () => {
    setLoading(true);
    let kw = dataFilterTab4
      ? encodeURIComponent(JSON.stringify(dataFilterTab4))
      : null;
    let URL = `${HISTORY_STAR.getThreeStar}?take=10&skip=${pageTab4}&order=DESC&keyword=${kw}`;
    const listData = await API.get(URL);

    const result = listData.data?.data?.result[0];

    if (result?.length > 0) {
      let dataCV = [];
      result?.map((item, index) => {
        let itemNew = {
          key: index,
          username: item?.username,
          effectivedAt: item.effectivedAt
            ? moment(item?.effectivedAt)
              .utcOffset(7)
              .format("DD-MM-YYYY HH:mm:ss")
            : null,
          createdAt: item.createdAt
            ? moment(item?.createdAt).utcOffset(7).format("DD-MM-YYYY HH:mm:ss")
            : null,
          multiple: +item?.multiple,
          point: +item?.point,
          option: item?.option,
          createdBy: item?.createdBy,
        };
        dataCV.push(itemNew);
      });
      setDataList(dataCV);
      setTotalRecordTab4(listData.data?.data?.result[1]);
    } else {
      setDataList([]);
      setTotalRecordTab3();
    }
    setLoading(false);
  };

  const exportDataTab4 = async () => {
    setLoading(true);
    let kw = dataFilterTab4
      ? encodeURIComponent(JSON.stringify(dataFilterTab4))
      : null;
    let URL = `${HISTORY_STAR.getThreeStar}?take=10000&skip=${pageTab4}&order=DESC&keyword=${kw}`;
    const listData = await API.get(URL);
    const result = listData.data?.data?.result[0];
    if (result?.length > 0) {
      let dataCV = [];
      result?.map((item) => {
        let itemNew = {
          username: item?.username,
          effectivedAt: item.effectivedAt
            ? moment(item?.effectivedAt)
              .utcOffset(7)
              .format("DD-MM-YYYY HH:mm:ss")
            : null,
          createdAt: item.createdAt
            ? moment(item?.createdAt).utcOffset(7).format("DD-MM-YYYY HH:mm:ss")
            : null,
          multiple: +item?.multiple,
          point: +item?.point,
          option: item?.option,
          createdBy: item?.createdBy,
        };
        dataCV.push(itemNew);
      });
      const header = [
        TEXT_DEF.text0002,
        TEXT_DEF.time_prize,
        TEXT_DEF.create_day,
        TEXT_DEF.multiple,
        TEXT_DEF.point,
        TEXT_DEF.nature,
        TEXT_DEF.create_person,
      ];
      downloadExcel({
        fileName: "lịch sử vé 3 sao người trúng giải",
        sheet: "sheets 1",
        tablePayload: {
          header: header,
          body: dataCV,
        },
      });
    }
    setLoading(false);
  };

  const onSearch = () => {
    let params = {};

    if (form.getFieldValue("usernameFilter"))
      params.username = form
        .getFieldValue("usernameFilter")
        .trim()
        .toLowerCase();
    if (form.getFieldValue("codeFilter"))
      params.ft = form.getFieldValue("codeFilter").trim().toLowerCase();
    if (dateFrom) {
      params.startDate = moment(dateFrom).format("YYYY-MM-DD");
      if (!dateTo) params.endDate = "2125-12-31";
    }
    if (dateTo) {
      params.endDate = moment(dateTo).format("YYYY-MM-DD");
      if (!dateFrom) params.endDate = "2023-01-01";
    }

    setDataFilterTab1(params ? params : undefined);
    setPageTab1(1);
    setDataFilterTab2(params ? params : undefined);
    setPageTab2(1);
    setDataFilterTab3(params ? params : undefined);
    setPageTab3(1);
  };

  const handleSearchThree = () => {
    let params = {};

    if (form.getFieldValue("usernameFilter"))
      params.username = form
        .getFieldValue("usernameFilter")
        .trim()
        .toLowerCase();
    if (dateFrom) {
      params.startEffectivedAt = moment(dateFrom).format("YYYY-MM-DD");
      if (!dateTo) params.endDate = "2125-12-31";
    }
    if (dateTo) {
      params.endEffectivedAt = moment(dateTo).format("YYYY-MM-DD");
      if (!dateFrom) params.endDate = "2023-01-01";
    }
    setDataFilterTab4(params ? params : undefined);
    setPageTab4(1);
  };

  const onShowDetail = (idLand) => {
    setIdLandSl(idLand);
    setIndexTab(2);
  };

  const tableColumsTab1 = [
    {
      title: TEXT_DEF.text0002,
      key: "username",
      dataIndex: "username",
      width: 150,
    },
    {
      title: TEXT_DEF.time_play,
      dataIndex: "pickedAt",
      key: "pickedAt",
    },
    {
      title: TEXT_DEF.time_play_confirm,
      dataIndex: "confirmedAt",
      key: "confirmedAt",
    },

    {
      title: TEXT_DEF.turn,
      key: "lượt",
      dataIndex: "turnIndex",
    },
    {
      title: TEXT_DEF.bonus_points,
      key: "point",
      dataIndex: "point",
    },
    {
      title: TEXT_DEF.play_process,
      key: "status",
      dataIndex: "status",
    },
    {
      title: TEXT_DEF.expired,
      key: "isExpired",
      dataIndex: "isExpired",
    },
    {
      title: TEXT_DEF.score_submission_process,
      key: "sendStatus",
      dataIndex: "sendStatus",
    },
    // {
    //   title: TEXT_DEF.desc,
    //   key: "description",
    //   dataIndex: "description",
    // },
  ];

  const tableColumsTab2 = [
    {
      title: TEXT_DEF.text0002,
      dataIndex: "username",
      key: "username",
    },
    {
      title: TEXT_DEF.ft,
      dataIndex: "ft",
      key: "ft",
    },
    {
      title: TEXT_DEF.time_play,
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: TEXT_DEF.time_play_confirm,
      dataIndex: "confirmedAt",
      key: "confirmedAt",
    },
    {
      title: TEXT_DEF.number_of_prizes,
      key: "awardTitle",
      dataIndex: "awardTitle",
    },
    {
      title: TEXT_DEF.used_point,
      key: "coin",
      dataIndex: "coin",
    },
    {
      title: TEXT_DEF.play_process,
      key: "pointStatus",
      dataIndex: "pointStatus",
    },
    {
      title: TEXT_DEF.Reward_Points,
      key: "point",
      dataIndex: "point",
    },
    {
      title: TEXT_DEF.round,
      key: "multiple",
      dataIndex: "multiple",
    },
  ];

  const tableColumsTab3 = [
    {
      title: TEXT_DEF.text0002,
      dataIndex: "username",
      key: "username",
    },
    {
      title: TEXT_DEF.text0020,
      key: "createdAt",
      dataIndex: "createdAt",
    },
    {
      title: TEXT_DEF.text_total,
      key: "deposit",
      dataIndex: "deposit",
    },
  ];

  const tableColumsTab4 = [
    {
      title: TEXT_DEF.text0002,
      dataIndex: "username",
      key: "username",
    },
    {
      title: TEXT_DEF.time_prize,
      key: "effectivedAt",
      dataIndex: "effectivedAt",
    },
    {
      title: TEXT_DEF.create_day,
      key: "createdAt",
      dataIndex: "createdAt",
    },
    {
      title: TEXT_DEF.multiple,
      key: "multiple",
      dataIndex: "multiple",
    },
    {
      title: TEXT_DEF.point,
      key: "point",
      dataIndex: "point",
    },
    {
      title: TEXT_DEF.nature,
      key: "option",
      dataIndex: "option",
    },
    {
      title: TEXT_DEF.create_person,
      key: "createdBy",
      dataIndex: "createdBy",
    },
  ];

  const onChangeDate = (e, type) => {
    console.log(moment(e).endOf("day").format('yyyy-MM-DD'))
    if (e) {
      if (type === "from") setDateFrom(moment(e.$d).startOf("day").format('yyyy-MM-DD'));
      else setDateTo(moment(e.$d).endOf("day").format('yyyy-MM-DD'));
    } else {
      if (type === "from") setDateFrom(null);
      else setDateTo(null);
    }
  };

  const onChangePage = (page) => {
    if (indexTab === 0) {
      setPageTab1(page);
    } else if (indexTab === 1) {
      setPageTab2(page);
    } else if (indexTab === 2) {
      setPageTab3(page);
    } else {
      setPageTab4(page);
    }
  };

  const showTotal = (total) => `Tổng cộng ${total} dữ liệu`;

  const renderTab1 = () => {
    return (
      <>
        <div className="col-12">
          <Form
            form={form}
            layout="inline"
            autoComplete="off"
            onFinish={() => onSearch()}
          >
            <div
              className="col-12"
              style={{ marginBottom: 15, display: "flex" }}
            >
              <div className="col-4">
                <Form.Item name="usernameFilter" label={TEXT_DEF.text0002}>
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item name="dateFrom" label={TEXT_DEF.text0032}>
                  <DatePicker
                    placeholder=""
                    onChange={(e) => onChangeDate(e, "from")}
                    format={"DD-MM-YYYY"}
                    style={{ width: "100%" }}
                    disabledTime={(d) =>
                      !d ||
                      d.isSameOrAfter(dateTo && moment(dateTo).add(1, "d"))
                    }
                  />
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item name="dateTo" label={TEXT_DEF.text0033}>
                  <DatePicker
                    placeholder=""
                    onChange={(e) => onChangeDate(e, "to")}
                    format={"DD-MM-YYYY"}
                    style={{ width: "100%" }}
                    disabledTime={(d) =>
                      !d || d.isSameOrBefore(dateFrom ? dateFrom : null)
                    }
                  />
                </Form.Item>
              </div>
            </div>
            <div className="payment-search">
              <Button htmlType="submit" type="primary">
                {TEXT_DEF.text0031}
              </Button>
              <Button
                style={{ marginLeft: 20 }}
                onClick={exportDataTab1}
                htmlType="button"
                type="primary"
              >
                {TEXT_DEF.text0050}
              </Button>
            </div>
          </Form>
        </div>
        <Spin spinning={loading} delay={500} size="large">
          <div style={{ display: "flex", width: "100%", marginTop: 20 }}>
            <Table
              key="dataHis"
              locale={locale}
              columns={tableColumsTab1}
              dataSource={dataList}
              pagination={false}
              bordered
              style={{ width: "100%", textAlign: "center" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              marginTop: 15,
              justifyContent: "flex-end",
            }}
          >
            <Pagination
              current={pageTab1}
              total={totalRecordTab1}
              onChange={onChangePage}
              defaultPageSize={10}
              showSizeChanger={false}
              showTotal={showTotal}
            />
          </div>
        </Spin>
      </>
    );
  };

  const renderTab2 = () => {
    return (
      <>
        <div className="col-12">
          <Form
            form={form}
            layout="inline"
            autoComplete="off"
            onFinish={() => onSearch()}
          >
            <div
              className="col-12"
              style={{ marginBottom: 15, display: "flex" }}
            >
              <div className="col-4">
                <Form.Item name="usernameFilter" label={TEXT_DEF.text0002}>
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item name="dateFrom" label={TEXT_DEF.text0032}>
                  <DatePicker
                    placeholder=""
                    onChange={(e) => onChangeDate(e, "from")}
                    format={"DD-MM-YYYY"}
                    style={{ width: "100%" }}
                    disabledTime={(d) =>
                      !d ||
                      d.isSameOrAfter(dateTo && moment(dateTo).add(1, "d"))
                    }
                  />
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item name="dateTo" label={TEXT_DEF.text0033}>
                  <DatePicker
                    placeholder=""
                    onChange={(e) => onChangeDate(e, "to")}
                    format={"DD-MM-YYYY"}
                    style={{ width: "100%" }}
                    disabledTime={(d) =>
                      !d || d.isSameOrBefore(dateFrom ? dateFrom : null)
                    }
                  />
                </Form.Item>
              </div>
            </div>
            <div className="col-12">
              <div className="col-4">
                <Form.Item name="codeFilter" label={TEXT_DEF.text_code}>
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              </div>
            </div>
            <div className="payment-search">
              <Button htmlType="submit" type="primary">
                {TEXT_DEF.text0031}
              </Button>
              <Button
                style={{ marginLeft: 20 }}
                onClick={exportDataTab2}
                htmlType="button"
                type="primary"
              >
                {TEXT_DEF.text0050}
              </Button>
            </div>
          </Form>
        </div>
        <Spin spinning={loading} delay={500} size="large">
          <div style={{ display: "flex", width: "100%", marginTop: 20 }}>
            <Table
              key="dataHis"
              locale={locale}
              columns={tableColumsTab2}
              dataSource={dataList}
              pagination={false}
              bordered
              style={{ width: "100%" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              marginTop: 15,
              justifyContent: "flex-end",
            }}
          >
            <Pagination
              current={pageTab2}
              total={totalRecordTab2}
              onChange={onChangePage}
              defaultPageSize={10}
              showSizeChanger={false}
              showTotal={showTotal}
            />
          </div>
        </Spin>
      </>
    );
  };

  const renderTab3 = () => {
    return (
      <>
        <div className="col-12">
          <Form
            form={form}
            layout="inline"
            autoComplete="off"
            onFinish={() => onSearch()}
          >
            <div
              className="col-12"
              style={{ marginBottom: 15, display: "flex" }}
            >
              <div className="col-4">
                <Form.Item name="usernameFilter" label={TEXT_DEF.text0002}>
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item name="dateFrom" label={TEXT_DEF.text0032}>
                  <DatePicker
                    placeholder=""
                    onChange={(e) => onChangeDate(e, "from")}
                    format={"DD-MM-YYYY"}
                    style={{ width: "100%" }}
                    disabledTime={(d) =>
                      !d ||
                      d.isSameOrAfter(dateTo && moment(dateTo).add(1, "d"))
                    }
                  />
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item name="dateTo" label={TEXT_DEF.text0033}>
                  <DatePicker
                    placeholder=""
                    onChange={(e) => onChangeDate(e, "to")}
                    format={"DD-MM-YYYY"}
                    style={{ width: "100%" }}
                    disabledTime={(d) =>
                      !d || d.isSameOrBefore(dateFrom ? dateFrom : null)
                    }
                  />
                </Form.Item>
              </div>
            </div>
            <div className="payment-search">
              <Button htmlType="submit" type="primary">
                {TEXT_DEF.text0031}
              </Button>
              <Button
                style={{ marginLeft: 20 }}
                onClick={exportDataTab3}
                htmlType="button"
                type="primary"
              >
                {TEXT_DEF.text0050}
              </Button>
            </div>
          </Form>
        </div>
        <Spin spinning={loading} delay={500} size="large">
          <div style={{ display: "flex", width: "100%", marginTop: 20 }}>
            <Table
              key="dataHis"
              locale={locale}
              columns={tableColumsTab3}
              dataSource={dataList}
              pagination={false}
              bordered
              style={{ width: "100%" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              marginTop: 15,
              justifyContent: "flex-end",
            }}
          >
            <Pagination
              current={pageTab3}
              total={totalRecordTab3}
              onChange={onChangePage}
              defaultPageSize={10}
              showSizeChanger={false}
              showTotal={showTotal}
            />
          </div>
        </Spin>
      </>
    );
  };
  const renderTab4 = () => {
    return (
      <>
        <div className="col-12">
          <Form
            form={form}
            layout="inline"
            autoComplete="off"
            onFinish={() => handleSearchThree()}
          >
            <div
              className="col-12"
              style={{ marginBottom: 15, display: "flex" }}
            >
              <div className="col-4">
                <Form.Item name="usernameFilter" label={TEXT_DEF.text0002}>
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item name="dateFrom" label={TEXT_DEF.text0032}>
                  <DatePicker
                    placeholder=""
                    onChange={(e) => onChangeDate(e, "from")}
                    format={"DD-MM-YYYY"}
                    style={{ width: "100%" }}
                    disabledTime={(d) =>
                      !d ||
                      d.isSameOrAfter(dateTo && moment(dateTo).add(1, "d"))
                    }
                  />
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item name="dateTo" label={TEXT_DEF.text0033}>
                  <DatePicker
                    placeholder=""
                    onChange={(e) => onChangeDate(e, "to")}
                    format={"DD-MM-YYYY"}
                    style={{ width: "100%" }}
                    disabledTime={(d) =>
                      !d || d.isSameOrBefore(dateFrom ? dateFrom : null)
                    }
                  />
                </Form.Item>
              </div>
            </div>
            <div className="payment-search">
              <Button htmlType="submit" type="primary">
                {TEXT_DEF.text0031}
              </Button>
              <Button
                style={{ marginLeft: 20 }}
                onClick={exportDataTab4}
                htmlType="button"
                type="primary"
              >
                {TEXT_DEF.text0050}
              </Button>
            </div>
          </Form>
        </div>
        <Spin spinning={loading} delay={500} size="large">
          <div style={{ display: "flex", width: "100%", marginTop: 20 }}>
            <Table
              key="dataHis"
              columns={tableColumsTab4}
              dataSource={dataList}
              pagination={false}
              bordered
              style={{ width: "100%" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              marginTop: 15,
              justifyContent: "flex-end",
            }}
          >
            <Pagination
              current={pageTab4}
              total={totalRecordTab4}
              onChange={onChangePage}
              defaultPageSize={10}
              showSizeChanger={false}
              showTotal={showTotal}
            />
          </div>
        </Spin>
      </>
    );
  };

  return (
    <div className="col-12">
      <div
        className="col-12"
        style={{
          borderBottom: "1px solid #CCC",
          paddingBottom: "2rem",
          marginBottom: "2rem",
        }}
      >
        <Radio.Group value={indexTab} onChange={onChangeTab}>
          {auth?.role === "supper" ||
            (auth?.role === "admin" &&
              roleArr.some(
                (el) => el === "tick_1_view" || el === "tick_1_edit"
              )) ? (
            <Radio.Button value={0}>{TEXT_DEF.ve_1_sao}</Radio.Button>
          ) : null}
          {auth?.role === "supper" ||
            (auth?.role === "admin" &&
              roleArr.some(
                (el) => el === "tick_2_view" || el === "tick_2_edit"
              )) ? (
            <Radio.Button value={1}>{TEXT_DEF.ve_2_sao}</Radio.Button>
          ) : null}
          {auth?.role === "supper" ||
            (auth?.role === "admin" &&
              PERMISSION_HIS_3.some((el) => roleArr.includes(el))) ? (
            <>
              <Radio.Button value={2}>{TEXT_DEF.ve_3_sao_1}</Radio.Button>
              <Radio.Button value={3}>{TEXT_DEF.ve_3_sao_2}</Radio.Button>
            </>
          ) : null}
        </Radio.Group>
      </div>

      {indexTab === 0
        ? renderTab1()
        : indexTab === 1
          ? renderTab2()
          : indexTab === 2
            ? renderTab3()
            : renderTab4()}
    </div>
  );
};

export default GrowHistory;
