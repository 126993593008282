import { Button, Input, InputNumber, Switch, Space, Badge, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { TEXT_DEF } from "../../../constant/text";
import { DAYS_WEEK } from "../../../constant/generate";
import "./style.css";
import { FiSave, FiTrash2 } from "react-icons/fi";
import API, { COMMON } from "../../../network/api";
import ModalCommon from "../../../components/Modal";
import moment from "moment";

const TicketTow = ({
  maintenanceTicket,
  onHandleChangeMode,
  setMaintenaceTicket,
  onShowModal,
}) => {
  const [day, setDay] = useState(moment().day().toString());
  const [dataMonday, setDataMonday] = useState();
  const [dataTuesday, setDataTuesday] = useState();
  const [dataWednesMonday, setDataWednesday] = useState();
  const [dataThursday, setDataThursday] = useState();
  const [dataFriday, setDataFriday] = useState();
  const [dataSaturday, setDataSaturday] = useState();
  const [dataSunday, setDataSunday] = useState();
  const [pointMoney2, setPointMoney2] = useState([]);
  const [roundArray2, setRoundArray2] = useState([]);
  const [coins, setCoins] = useState([]);
  const [coinLocal, setCoinLocal] = useState("");

  const [isShowModalConfirm, setIsShowModalConfirm] = useState(0);
  const [isShowModalSuccess, setIsShowModalSuccess] = useState(false);
  const [isPending, setIsPending] = useState(false);

  const [indexItem, setIndexItem] = useState(0)
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getDataTicket2();
  }, []);

  // lấy data của các thứ trong tuần
  const getDataTicket2 = async () => {
    try {
      setLoading(true)
      const res = await API.get(
        `${COMMON}/all?take=100000000000&skip=1&order=ASC&keyword=%7B%22module%22%3A%20%22TICKET2%22%7D`
      );
      const data = res.data.data.result[0];

      if (res.data.data.code && data) {
        setDataMonday(
          data
            ? data.filter((el) => el.item === "1" && el.itemCode === "TITLE")
            : []
        );
        setDataTuesday(
          data
            ? data.filter((el) => el.item === "2" && el.itemCode === "TITLE")
            : []
        );
        setDataWednesday(
          data
            ? data.filter((el) => el.item === "3" && el.itemCode === "TITLE")
            : []
        );
        setDataThursday(
          data
            ? data.filter((el) => el.item === "4" && el.itemCode === "TITLE")
            : []
        );
        setDataFriday(
          data
            ? data.filter((el) => el.item === "5" && el.itemCode === "TITLE")
            : []
        );
        setDataSaturday(
          data
            ? data.filter((el) => el.item === "6" && el.itemCode === "TITLE")
            : []
        );
        setDataSunday(
          data
            ? data.filter((el) => el.item === "0" && el.itemCode === "TITLE")
            : []
        );
        setPointMoney2(data.filter((el) => el.itemCode === "OPTION_POINT"));
        setRoundArray2(data.filter((el) => el.itemCode === "OPTION_MULTIPE"));
        const dataCoins = data.filter((el) => el.itemCode === "CONDITION_COIN");
        setCoins(
          dataCoins.map((item) => ({
            ...item,
            value: Number(item?.value),
          }))
        );
        console.log("POINT", data.filter((el) => el.itemCode === "OPTION_POINT"))
      }
    } catch (err) { }
    finally {
      setLoading(false);
    }
  };

  // dùng switch để render data các thứ trong tuần
  const renderDataForday = (key) => {
    switch (key) {
      case "0":
        return dataSunday;
      case "1":
        return dataMonday;
      case "2":
        return dataTuesday;
      case "3":
        return dataWednesMonday;
      case "4":
        return dataThursday;
      case "5":
        return dataFriday;
      case "6":
        return dataSaturday;
    }
  };

  const handleChange = (value) => {
    setDay(value);
  };

  // hàm này dùng để add form ở dạng view
  const handleAddWeedDayForm = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const newData = {
      value: "",
      value1: "",
      value2: "",
      module: "TICKET2",
      item: day,
      itemCode: "TITLE",
    };
    switch (day) {
      case "0":
        return setDataSunday([...dataSunday, newData]);
      case "1":
        return setDataMonday([...dataMonday, newData]);
      case "2":
        return setDataTuesday([...dataTuesday, newData]);
      case "3":
        return setDataWednesday([...dataWednesMonday, newData]);
      case "4":
        return setDataThursday([...dataThursday, newData]);
      case "5":
        return setDataFriday([...dataFriday, newData]);
      case "6":
        return setDataSaturday([...dataSaturday, newData]);
    }
  };

  // hàm này để check add or update giải thưởng
  const handleAddOrUpdatePrize = async (id, idex) => {
    if (
      renderDataForday(day)[idex]?.value === "" ||
      renderDataForday(day)[idex]?.value1 === "" ||
      renderDataForday(day)[idex]?.value2 === ""
    )
      return;
    try {
      const body = {
        module: renderDataForday(day)[idex].module,
        item: day,
        itemCode: renderDataForday(day)[idex].itemCode,
        value: renderDataForday(day)[idex]?.value,
        value1: renderDataForday(day)[idex]?.value1,
        value2: renderDataForday(day)[idex]?.value2,
      };
      if (id) {
        await API.patch(`${COMMON}/${id}`, body);
      } else {
        await API.post(`${COMMON}/create`, body);
        await getDataTicket2();
      }
    } catch (err) {
      console.log(err);
    }
  };

  // hàm này dùng để onchange input form giải thưởng
  const handleChangeInput = (e, index, field) => {
    switch (day) {
      case "0":
        const updateDataSunday = [...dataSunday];
        updateDataSunday[index] = {
          ...updateDataSunday[index],
          [field]: e.target.value,
        };
        return setDataSunday(updateDataSunday);
      case "1":
        const updateDataMonday = [...dataMonday];
        updateDataMonday[index] = {
          ...updateDataMonday[index],
          [field]: e.target.value,
        };
        return setDataMonday(updateDataMonday);
      case "2":
        const updateDataTuesday = [...dataTuesday];
        updateDataTuesday[index] = {
          ...updateDataTuesday[index],
          [field]: e.target.value,
        };
        return setDataTuesday(updateDataTuesday);
      case "3":
        const updateDataWenday = [...dataWednesMonday];
        updateDataWenday[index] = {
          ...updateDataWenday[index],
          [field]: e.target.value,
        };
        return setDataWednesday(updateDataWenday);
      case "4":
        const updatedataThursday = [...dataThursday];
        updatedataThursday[index] = {
          ...updatedataThursday[index],
          [field]: e.target.value,
        };
        return setDataThursday(updatedataThursday);
      case "5":
        const updateDataFriday = [...dataFriday];
        updateDataFriday[index] = {
          ...updateDataFriday[index],
          [field]: e.target.value,
        };
        return setDataFriday(updateDataFriday);
      case "6":
        const updateDataSaturday = [...dataSaturday];
        updateDataSaturday[index] = {
          ...updateDataSaturday[index],
          [field]: e.target.value,
        };
        return setDataSaturday(updateDataSaturday);
    }
  };

  // hàm xóa ở view và xóa call api
  const handleDeletePrize = async (id, index) => {
    try {
      if (id) {
        await API.delete(`${COMMON}/${id}`);
        await getDataTicket2();
      } else {
        switch (day) {
          case "0":
            const updateDataSunday = [...dataSunday];
            updateDataSunday.splice(index, 1);
            return setDataSunday(updateDataSunday);
          case "1":
            const updateDataMonday = [...dataMonday];
            updateDataMonday.splice(index, 1);
            return setDataMonday(updateDataMonday);
          case "2":
            const updateDataTuesday = [...dataTuesday];
            updateDataTuesday.splice(index, 1);
            return setDataTuesday(updateDataTuesday);
          case "3":
            const updateDataWenday = [...dataWednesMonday];
            updateDataWenday.splice(index, 1);
            return setDataWednesday(updateDataWenday);
          case "4":
            const updatedataThursday = [...dataThursday];
            updatedataThursday.splice(index, 1);
            return setDataThursday(updatedataThursday);
          case "5":
            const updateDataFriday = [...dataFriday];
            updateDataFriday.splice(index, 1);
            return setDataFriday(updateDataFriday);
          case "6":
            const updateDataSaturday = [...dataSaturday];
            updateDataSaturday.splice(index, 1);
            return setDataSaturday(updateDataSaturday);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  // hàm change input điểm và vòng cược
  const handleChangeInputPointAndRound = (e, index, field, key) => {
    if (key === "point") {
      const updateDataPoint = [...pointMoney2];
      updateDataPoint[index] = {
        ...updateDataPoint[index],
        [field]: e,
      };

      setPointMoney2(updateDataPoint);
    }
    if (key === "round") {
      const updateDataRound = [...roundArray2];
      updateDataRound[index] = {
        ...updateDataRound[index],
        [field]: e,
      };
      setRoundArray2(updateDataRound);
    }
  };

  // hàm thêm data ở view or call api
  const handleAddPointAndRoundTicket2 = async (id, key, field) => {

    if (!id) return;
    const newData = {
      parentId: id,
      value: "1",
      value1: "0",
      module: "TICKET2",
      item: day,
      itemCode: field,
    };
    if (field === "OPTION_POINT") {
      setPointMoney2([...pointMoney2, newData]);
    } else if (field === "OPTION_MULTIPE") {
      setRoundArray2([...roundArray2, newData]);
    }
  };

  // hàm xóa data ở view or call api
  const handleDeletePointAndRound = async (id, index, field) => {
    // if (id) {
    try {
      if (id) {
        await API.delete(`${COMMON}/${id}`);
        await getDataTicket2();
      }
      if (field === "point") {
        const updateDataPoint = [...pointMoney2];
        updateDataPoint.splice(index, 1);
        return setPointMoney2(updateDataPoint);
      } else {
        const updateDataRound = [...roundArray2];
        updateDataRound.splice(index, 1);
        return setRoundArray2(updateDataRound);
      }
    } catch (err) {
      console.log(err);
    } finally {
      if (id) {
        setIsShowModalSuccess(true)
      }
    }
    // } else {

    // }
  };

  // hàm update tổng của vé 2 sao
  // const handleUpdateTicket2 = async (item, index) => {
  //   try {
  //     setIsShowModalConfirm(false);
  //     setIsShowModalSuccess(true);
  //     let data = [];

  //     if (dataMonday.length > 0) {
  //       dataMonday.forEach((el) => {
  //         data.push(el);
  //       });
  //     }

  //     if (dataTuesday.length > 0) {
  //       dataTuesday.forEach((el) => {
  //         data.push(el);
  //       });
  //     }

  //     if (dataWednesMonday.length > 0) {
  //       dataWednesMonday.forEach((el) => {
  //         data.push(el);
  //       });
  //     }

  //     if (dataThursday.length > 0) {
  //       dataThursday.forEach((el) => {
  //         data.push(el);
  //       });
  //     }

  //     if (dataFriday.length > 0) {
  //       dataFriday.forEach((el) => {
  //         data.push(el);
  //       });
  //     }

  //     if (dataSaturday.length > 0) {
  //       dataSaturday.forEach((el) => {
  //         data.push(el);
  //       });
  //     }

  //     if (dataSunday.length > 0) {
  //       dataSunday.forEach((el) => {
  //         data.push(el);
  //       });
  //     }

  //     if (pointMoney2.length > 0) {
  //       pointMoney2.forEach((el) => {
  //         data.push(el);
  //       });
  //     }

  //     if (roundArray2.length > 0) {
  //       roundArray2.forEach((el) => {
  //         data.push(el);
  //       });
  //     }

  //     if (coins.length > 0) {
  //       coins.forEach((el) => {
  //         data.push(el);
  //       });
  //     }

  //     const body = {
  //       sysConfigs: data,
  //     };

  //     await API.patch(`${COMMON}/ids`, body);
  //   } catch (err) {
  //     console.log(err);
  //   } finally {
  //   }
  // };

  const handleChangeCoin = (e, id) => {
    const newData = coins.map((item) =>
      item.id === id ? { ...item, value: e } : item
    );

    setCoins(newData);
  };

  // const handleAddOrUpdateCoin = async (id, idDay, index) => {
  //   if (!idDay) return;
  //   try {
  //     setIsPending(true);
  //     const body = {
  //       parentId: id ? coins.find((el) => el.id === id).parentId : idDay,
  //       value: id
  //         ? coins.find((el) => el.id === id).value.toString()
  //         : coinLocal[index].value.toString(),
  //       itemCode: "CONDITION_COIN",
  //       module: "TICKET2",
  //       item: day,
  //     };
  //     if (id) {
  //       await API.patch(`${COMMON}/${id}`, body);
  //       await getDataTicket2();
  //     } else {
  //       await API.post(`${COMMON}/create`, body);
  //       await getDataTicket2();
  //     }
  //   } catch (err) {
  //     setIsPending(false);
  //     console.log(err);
  //   } finally {
  //     setIsPending(false);
  //   }
  // };

  const handleChangeCoinLocal = (e, value, index) => {
    const updateData = [...coinLocal];
    updateData[index] = {
      ...updateData[index],
      value: e,
    };
    setCoinLocal(updateData);
  };

  // const handleAddAndUpdatePointdAndround = async (id, idDay, index, field) => {
  //   try {
  //     setIsPending(true);
  //     const body = {
  //       parentId:
  //         field === "OPTION_POINT"
  //           ? pointMoney2[index].parentId
  //           : roundArray2[index].parentId,
  //       module: "TICKET2",
  //       item: day,
  //       value:
  //         field === "OPTION_POINT"
  //           ? pointMoney2[index].value.toString()
  //           : roundArray2[index].value.toString(),
  //       value1:
  //         field === "OPTION_POINT"
  //           ? pointMoney2[index].value1.toString()
  //           : roundArray2[index].value1.toString(),
  //       itemCode: field,
  //     };
  //     if (id) {
  //       await API.patch(`${COMMON}/${id}`, body);
  //       await getDataTicket2();
  //     } else {
  //       await API.post(`${COMMON}/create`, body);
  //       await getDataTicket2();
  //     }
  //   } catch (err) {
  //     setIsPending(false);
  //     console.log(err);
  //   } finally {
  //     setIsPending(false);
  //   }
  // };

  const convertValueToString = (item) => {

    let data = []
    item.forEach(e => {
      e = {
        ...e,
        value: Number(e?.value).toString(),
        value1: Number(e?.value1).toString()
      }
      data.push(e);
    });

    return data
  }

  //SAVE PRIZE
  const saveItemPrize = async () => {
    try {

      let Id = renderDataForday(day)[indexItem]?.id

      let checkCoin = coins.filter(e => e.parentId === Id).length > 0
      let body = {

        sysConfigs: Id ? [
          renderDataForday(day)[indexItem],
          checkCoin ? coins.filter(e => e.parentId === Id)[0]
            : {
              parentId: Id,
              value: coinLocal[indexItem].value.toString(),
              itemCode: "CONDITION_COIN",
              module: "TICKET2",
              item: day,
            },
          // convertValueToString(pointMoney2.filter(e => e.parentId === Id)[0]),
          // roundArray2.filter(e => e.parentId === Id)[0],
        ]
          : [renderDataForday(day)[indexItem]],
      };

      convertValueToString(pointMoney2.filter(e => e.parentId === Id)).forEach(e => {
        body.sysConfigs.push(e)
      });

      roundArray2.filter(e => e.parentId === Id).forEach(e => {
        body.sysConfigs.push(e)
      });

      let isCheck = false
      if (Id && checkCoin) {
        const res = await API.patch(`${COMMON}/ids`, body);

        isCheck = res.data.data.result.sysConfigs.length > 0;
      } else {
        const res = await API.post(`${COMMON}/create-list`, body);

        isCheck = res.data.data.result.sysConfigs.length > 0;
      }

      if (isCheck) {
        setIndexItem(0)
        await getDataTicket2()
      }
      setIsShowModalConfirm(0)
      setIsShowModalSuccess(true)
    } catch (err) {
      console.log(err);
    } finally {

    }
    // setIsShowModalSuccess(true)
  }

  const Confirm = (type, index) => {
    setIndexItem(index)
    setIsShowModalConfirm(type)
  }

  const removeItemPrize = async () => {
    try {
      debugger
      let Id = renderDataForday(day)[indexItem]?.id
      if (Id) {
        const res = await API.delete(`${COMMON}/${Id}`);

        let isCheck = res.data.data.code === 20005;

        if (isCheck) {
          setIsShowModalConfirm(0)
          setIsShowModalSuccess(true)
          await getDataTicket2()
        }

      } else {
        switch (day) {
          case "0":
            const updateDataSunday = [...dataSunday];
            updateDataSunday.splice(indexItem, 1);
            return setDataSunday(updateDataSunday);
          case "1":
            const updateDataMonday = [...dataMonday];
            updateDataMonday.splice(indexItem, 1);
            return setDataMonday(updateDataMonday);
          case "2":
            const updateDataTuesday = [...dataTuesday];
            updateDataTuesday.splice(indexItem, 1);
            return setDataTuesday(updateDataTuesday);
          case "3":
            const updateDataWenday = [...dataWednesMonday];
            updateDataWenday.splice(indexItem, 1);
            return setDataWednesday(updateDataWenday);
          case "4":
            const updatedataThursday = [...dataThursday];
            updatedataThursday.splice(indexItem, 1);
            return setDataThursday(updatedataThursday);
          case "5":
            const updateDataFriday = [...dataFriday];
            updateDataFriday.splice(indexItem, 1);
            return setDataFriday(updateDataFriday);
          case "6":
            const updateDataSaturday = [...dataSaturday];
            updateDataSaturday.splice(indexItem, 1);
            return setDataSaturday(updateDataSaturday);
        }
      }

    } catch (err) {
      console.log(err);
    } finally {
      setIndexItem(0)
    }
  }

  return (
    <>
      {
        loading ?
          <div className="col-12 d-flex justify-content-center">
            <Spin size="large" />
          </div>
          :
          <div className="col-12">
            <div className="col-12" style={{ display: "flex", alignItems: "center" }}>
              <div className="col-2">{TEXT_DEF.text0057}:</div>
              <div className="col-10" style={{ display: "flex" }}>
                <Switch
                  style={{ marginTop: "5px", width: 60 }}
                  checked={maintenanceTicket?.value}
                  checkedChildren="Bật"
                  unCheckedChildren="Tắt"
                  onChange={(e) => setMaintenaceTicket((prev) => ({
                    ...prev,
                    value: e,
                  }))} />
                <Button
                  type="primary"
                  onClick={(e) => onShowModal(e, "mainTiket2")}
                  style={{
                    marginLeft: "2rem",
                  }}
                >
                  {TEXT_DEF.update}
                </Button>
              </div>
            </div>
            {/* <div className="col-12 mt-5" style={{ display: "flex" }}>
              <div
                className="col-2"
                style={{ display: "flex", alignItems: "center" }}
              >
                Chọn thứ:
              </div>
              <Select
                // defaultValue="1"
                value={day}
                onChange={handleChange}
                style={{ width: 200 }}
                options={DAYS_WEEK}
              />
            </div> */}

            <div className="col-12 mt-5" style={{ display: "flex", justifyContent: "space-evenly" }}>
              <Space align="center" size={[28, 28]} wrap>
                {DAYS_WEEK && DAYS_WEEK.length > 0 && DAYS_WEEK.map((item, index) => (
                  <Badge.Ribbon text={renderDataForday(item.value)?.length + ' Giải'} key={index} className="mr-4" size="small">
                    <Button onClick={() => handleChange(item.value)} size="large" style={{ color: `${(Number(day) == index + 1 || (Number(day) == 0 && index == 6)) ? '#1677ff' : ''}`, borderColor: `${(Number(day) == index + 1 || (Number(day) == 0 && index == 6)) ? '#1677ff' : ''}` }}>
                      {item.label}
                    </Button>
                  </Badge.Ribbon>

                ))}
              </Space>
            </div>

            <div>
              <div
                className="col-12"
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className="wrapper" style={{ display: "flex" }}>
                  <div className="prize">
                    <div className="body-prize">
                      <div
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {renderDataForday(day)?.map((itemDay, index) => {
                          return (
                            <div key={index} style={{ display: "flex", flexDirection: "row" }}>
                              <div
                                style={{
                                  display: "flex",

                                  alignItems: "center",
                                  border: "1px solid #8889",
                                  borderRadius: "10px",
                                  marginBottom: "30px",
                                  width: "900px",
                                  boxShadow: "8px 6px 10px #888888"
                                }}
                                key={index}
                              >
                                <div
                                  style={{
                                    alignItems: "center",
                                    justifyContent: "center",
                                    display: "flex",
                                    marginTop: "2.5rem",
                                    marginBottom: "2.5rem",
                                    flexDirection: "column",
                                  }}
                                  key={itemDay.id}
                                >
                                  <div style={{
                                    display: "flex", alignItems: "center",
                                    justifyContent: "center",
                                    display: "flex",
                                  }}>
                                    <div className="body-price">
                                      <div className="body-row">
                                        <div
                                          style={{
                                            alignItems: "center",
                                            justifyContent: "center",
                                            display: "flex",
                                            fontWeight: "600"
                                          }}
                                        >
                                          {TEXT_DEF.title}:
                                        </div>
                                        <Input
                                          required
                                          spellCheck="false"
                                          value={itemDay?.value}
                                          style={{ width: "100%", marginLeft: "1rem" }}
                                          onChange={(e) => handleChangeInput(e, index, "value")} />
                                      </div>
                                      <div className="body-row">
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "end",
                                            width: "25%",
                                            fontWeight: "600"
                                          }}
                                        >
                                          {TEXT_DEF.message_1}:
                                        </div>
                                        <Input
                                          required
                                          spellCheck="false"
                                          value={itemDay?.value1}
                                          style={{ width: "100%", marginLeft: "1rem" }}
                                          onChange={(e) => handleChangeInput(e, index, "value1")} />
                                      </div>
                                      <div className="body-row">
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "end",
                                            width: "25%",
                                            fontWeight: "600"
                                          }}
                                        >
                                          {TEXT_DEF.message_2}:
                                        </div>
                                        <Input
                                          required
                                          spellCheck="false"
                                          value={itemDay?.value2}
                                          style={{ width: "100%", marginLeft: "1rem" }}
                                          onChange={(e) => handleChangeInput(e, index, "value2")} />
                                      </div>
                                    </div>
                                    {/* <div
style={{
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "space-around",
}}
>
<Button
  danger
  onClick={() =>
    handleDeletePrize(itemDay.id, index)
  }
>
  Xóa
</Button>
<Button
  type="primary"
  onClick={() =>
    handleAddOrUpdatePrize(itemDay.id, index)
  }
>
  Lưu
</Button>
</div> */}
                                  </div>
                                </div>

                                <div
                                  className="money-round"
                                  style={{ borderLeft: "1.5px solid #8885", height: "100%" }}
                                >
                                  {itemDay.id && (
                                    <div className="body-money-round">
                                      {/* check điều kiện tổng coin theo ngày dựa vào parentId của giải thưởng */}
                                      {coins.find(
                                        (el) => el.parentId === itemDay.id
                                      ) ? (
                                        coins.map((itemCoin) => {
                                          if (itemCoin.parentId === itemDay.id) {
                                            return (
                                              <div
                                                key={itemCoin.id}
                                                style={{
                                                  marginTop: "1rem",
                                                  width: "100%",
                                                  alignItems: "center",
                                                  justifyContent: "center",
                                                  display: "flex",
                                                  paddingBottom: "1rem",
                                                  borderBottom: "1.5px solid #8885",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    // width: "23%",
                                                    display: "flex",
                                                    justifyContent: "end",
                                                    marginRight: "2rem",
                                                    fontWeight: "600"
                                                  }}
                                                >
                                                  {TEXT_DEF.text_coins}:
                                                </div>
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    // marginRight: "3rem",
                                                  }}
                                                >
                                                  <InputNumber
                                                    type="number"
                                                    value={itemCoin?.value}
                                                    min={1}
                                                    onChange={(e) => handleChangeCoin(e, itemCoin.id)} />
                                                </div>
                                                {/* <div>
                                            <Button
                                              type="primary"
                                              htmlType="button"
                                              onClick={() =>
                                                handleAddOrUpdateCoin(
                                                  itemCoin.id,
                                                  itemDay.id,
                                                  null
                                                )
                                              }
                                              disabled={isPending}
                                            >
                                              Lưu
                                            </Button>
                                          </div> */}
                                              </div>
                                            );
                                          }
                                        })
                                      ) : (
                                        <div
                                          style={{
                                            marginTop: "1rem",
                                            width: "100%",
                                            alignItems: "center",
                                            display: "flex",
                                            paddingBottom: "1rem",
                                            borderBottom: "1.5px solid #8885",
                                          }}
                                        >
                                          <div
                                            style={{
                                              // width: "23%",
                                              display: "flex",
                                              justifyContent: "end",
                                              marginRight: "2rem",
                                              fontWeight: "600"
                                            }}
                                          >
                                            {TEXT_DEF.text_coins}:
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              // marginRight: "3rem",
                                            }}
                                          >
                                            <InputNumber
                                              value={coinLocal?.value}
                                              onChange={(e) => handleChangeCoinLocal(
                                                e,
                                                itemDay.id,
                                                index
                                              )}
                                              type="number"
                                              min={1} />
                                          </div>
                                          {/* <div>
                          <Button
                            type="primary"
                            htmlType="button"
                            onClick={() =>
                              handleAddOrUpdateCoin(
                                null,
                                itemDay.id,
                                index
                              )
                            }
                          >
                            Lưu
                          </Button>
                        </div> */}
                                        </div>
                                      )}
                                      <div
                                        style={{
                                          borderBottom: "1.5px solid #8885",
                                        }}
                                      >
                                        <div
                                          style={{
                                            alignItems: "center",
                                            justifyContent: "center",
                                            display: "flex",
                                            width: "100%",
                                          }}
                                        >
                                          {/* <div
                                  style={{
                                    display: "flex",
                                    marginRight: "3rem",
                                    textAlign: "center",
                                    justifyContent: "center",
                                    width: "16%",
                                    fontWeight: "600"
                                  }}
                                >
                                  {TEXT_DEF.precent_prize}:
                                </div> */}
                                          <div style={{
                                            alignItems: "center",
                                            justifyContent: "center",
                                            display: "flex",
                                            flexDirection: "column",
                                          }}>
                                            {/* check điểm thưởng render dựa vào parentId của giải thưởng */}
                                            {pointMoney2.map((itemPoint, index) => {
                                              if (itemPoint.parentId === itemDay.id) {
                                                return (
                                                  <div
                                                    key={index}
                                                    style={{
                                                      display: "flex",
                                                      width: "100%",
                                                      marginTop: "1rem",
                                                      justifyContent: "center"
                                                    }}
                                                  >
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        width: "70%",
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          width: "15rem",
                                                        }}
                                                      >
                                                        <div
                                                          style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            // width: "55%",
                                                            justifyContent: "end",
                                                            fontWeight: "600"
                                                          }}
                                                        >
                                                          {TEXT_DEF.point}:
                                                        </div>
                                                        <InputNumber
                                                          type="number"
                                                          value={itemPoint?.value}
                                                          min={0}
                                                          defaultValue={0}
                                                          max={9999}
                                                          style={{
                                                            marginLeft: "1rem",
                                                            height: "2rem",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            width: "70%",
                                                          }}
                                                          onChange={(e) => handleChangeInputPointAndRound(
                                                            e,
                                                            index,
                                                            "value",
                                                            "point"
                                                          )} />
                                                      </div>
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          marginLeft: "1rem",
                                                          fontWeight: "600"
                                                        }}
                                                      >
                                                        <div>{TEXT_DEF.percent}:</div>
                                                        <InputNumber
                                                          type="number"
                                                          defaultValue={0}
                                                          min={0}
                                                          max={1}
                                                          step={0.1}
                                                          value={itemPoint?.value1}
                                                          onChange={(e) => handleChangeInputPointAndRound(
                                                            e,
                                                            index,
                                                            "value1",
                                                            "point"
                                                          )}
                                                          style={{
                                                            marginLeft: "1rem",
                                                            height: "2rem",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            width: "50%",
                                                          }} />
                                                      </div>
                                                    </div>
                                                    <div style={{ alignItems: "center" }}>
                                                      <Button
                                                        danger
                                                        ghost
                                                        size="small"
                                                        onClick={() => handleDeletePointAndRound(
                                                          itemPoint.id,
                                                          index,
                                                          "point"
                                                        )}
                                                        style={{
                                                          marginRight: "1rem",
                                                          cursor: "pointer",
                                                        }}
                                                        disabled={isPending}
                                                      >
                                                        -
                                                      </Button>
                                                    </div>
                                                    {/* <div>
                                <Button
                                  type="primary"
                                  onClick={() =>
                                    handleAddAndUpdatePointdAndround(
                                      itemPoint.id,
                                      itemDay.id,
                                      index,
                                      "OPTION_POINT"
                                    )
                                  }
                                >
                                  Lưu
                                </Button>
                              </div> */}
                                                  </div>
                                                );
                                              }
                                            })}
                                          </div>
                                        </div>
                                        <div
                                          style={{
                                            marginTop: "0.2rem",
                                            marginBottom: "0.2rem",
                                            display: "flex",
                                            justifyContent: "space-evenly",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Button
                                            type="primary"
                                            ghost
                                            onClick={() => handleAddPointAndRoundTicket2(
                                              itemDay.id,
                                              itemDay.item,
                                              "OPTION_POINT"
                                            )}
                                            style={{
                                              marginTop: "0.5rem",
                                              marginBottom: "0.5rem",
                                              cursor: "pointer"
                                            }}
                                          >
                                            +
                                          </Button>

                                        </div>
                                      </div>
                                      <div>
                                        <div
                                          style={{
                                            display: "flex",
                                            width: "100%",
                                            alignItems: "center",
                                          }}
                                        >
                                          {/* <div
                                  style={{
                                    display: "flex",
                                    marginRight: "3rem",
                                    textAlign: "center",
                                    justifyContent: "end",
                                    width: "16%",
                                    fontWeight: "600"
                                  }}
                                >
                                  {TEXT_DEF.precent_round}:
                                </div> */}
                                          <div>
                                            {/* check vòng cược render dựa vào parentId của giải thưởng */}
                                            {roundArray2.map((itemRound, index) => {
                                              if (itemRound.parentId === itemDay.id) {
                                                return (
                                                  <div
                                                    key={itemRound.id}
                                                    style={{
                                                      display: "flex",
                                                      width: "100%",
                                                      marginTop: "1rem",
                                                      justifyContent: "center"
                                                    }}
                                                  >
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        width: "70%",
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          width: "15rem",
                                                        }}
                                                      >
                                                        <div
                                                          style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            // width: "55%",
                                                            justifyContent: "end",
                                                            fontWeight: "600"
                                                          }}
                                                        >
                                                          {TEXT_DEF.number_round}:
                                                        </div>
                                                        <InputNumber
                                                          type="number"
                                                          value={itemRound?.value}
                                                          min={0}
                                                          defaultValue={1}
                                                          max={9999}
                                                          onChange={(e) => handleChangeInputPointAndRound(
                                                            e,
                                                            index,
                                                            "value",
                                                            "round"
                                                          )}
                                                          style={{
                                                            marginLeft: "1rem",
                                                            height: "2rem",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            width: "70%",
                                                          }} />
                                                      </div>
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          marginLeft: "1rem",
                                                          fontWeight: "600"
                                                        }}
                                                      >
                                                        <div>{TEXT_DEF.percent}:</div>
                                                        <InputNumber
                                                          type="number"
                                                          value={itemRound?.value1}
                                                          defaultValue={0}
                                                          min={0}
                                                          max={1}
                                                          step={0.1}
                                                          style={{
                                                            marginLeft: "1rem",
                                                            height: "2rem",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            width: "50%",
                                                          }}
                                                          onChange={(e) => handleChangeInputPointAndRound(
                                                            e,
                                                            index,
                                                            "value1",
                                                            "round"
                                                          )} />
                                                      </div>
                                                    </div>
                                                    <div style={{ alignItems: "center" }}>
                                                      <Button
                                                        danger
                                                        ghost
                                                        size="small"
                                                        onClick={() => handleDeletePointAndRound(
                                                          itemRound.id,
                                                          index,
                                                          "round"
                                                        )}
                                                        style={{
                                                          marginRight: "1rem",
                                                          cursor: "pointer",
                                                        }}
                                                      >
                                                        -
                                                      </Button>
                                                    </div>
                                                    {/* <div>
                                                  <Button
                                                    type="primary"
                                                    onClick={() =>
                                                      handleAddAndUpdatePointdAndround(
                                                        itemRound.id,
                                                        itemDay.id,
                                                        index,
                                                        "OPTION_MULTIPE"
                                                      )
                                                    }
                                                  >
                                                    Lưu
                                                  </Button>
                                                </div> */}
                                                  </div>
                                                );
                                              }
                                            })}
                                          </div>
                                        </div>

                                        <div
                                          style={{
                                            marginTop: "0.5rem",
                                            marginBottom: "0.5rem",
                                            display: "flex",
                                            justifyContent: "space-evenly",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Button
                                            type="primary"
                                            ghost
                                            onClick={() => handleAddPointAndRoundTicket2(
                                              itemDay.id,
                                              itemDay.item,
                                              "OPTION_MULTIPE"
                                            )}
                                            style={{
                                              marginTop: "0.5rem",
                                              marginBottom: "0.5rem",
                                              cursor: "pointer",
                                            }}
                                          >
                                            +
                                          </Button>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>

                              </div><div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  flexDirection: "column",
                                  justifyContent: "space-evenly",
                                  padding: "20px",
                                  height: "100%"
                                }}
                              >
                                <Button
                                  size="large"
                                  type="primary"
                                  shape="rounded"
                                  icon={<FiSave />}
                                  onClick={() => Confirm(1, index)} />

                                <Button
                                  danger
                                  size="large"
                                  icon={<FiTrash2 />}
                                  onClick={() => Confirm(2, index)} />
                              </div>
                            </div>

                          );
                        })}
                        <div
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                            height: "100%",
                            padding: "1rem 0",
                          }}
                        >
                          <Button
                            type="primary"
                            ghost
                            onClick={(e) => handleAddWeedDayForm(e)}
                          >
                            +
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div
              className="col-12 mt-5"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                htmlType="button"
                type="primary"
                onClick={(e) => setIsShowModalConfirm(true)}
              >
                {TEXT_DEF.update}
              </Button>
            </div> */}

            {isShowModalConfirm > 0 && (
              <ModalCommon
                isShow={isShowModalConfirm > 0 ? true : false}
                message={TEXT_DEF.text0062}
                txtBtnAcc={TEXT_DEF.text0008}
                textBtnClose={TEXT_DEF.text0023}
                actionNext={isShowModalConfirm === 1 ? () => saveItemPrize() : () => removeItemPrize()}
                close={() => setIsShowModalConfirm(0)} />
            )}
            {isShowModalSuccess && (
              <ModalCommon
                isShow={isShowModalSuccess}
                message={TEXT_DEF.text0063}
                txtBtnAcc={TEXT_DEF.text0007}
                actionNext={() => {
                  setIsShowModalSuccess(false);
                }}
                close={() => {
                  setIsShowModalSuccess(false);
                }} />
            )}
          </div>
      }
    </>
  );
};

export default TicketTow;
