import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import { Radio } from "antd";
import API, { COMMON, Ticket3 } from "../../network/api";
import "moment-timezone";
import ModalCommon from "../../components/Modal";
import { TEXT_DEF } from "../../constant/text";
import TicketOne from "./ticketOne";
import TicketThree from "./ticketThree";
import TicketTow from "./ticketTow";
import moment from "moment";

import { Form } from "antd";

const SettingSys = () => {
  const [form] = Form.useForm();

  const auth = JSON.parse(localStorage.getItem("auth"));
  const roleLocal = localStorage.getItem("roles");
  const roleArr = roleLocal?.split(",");

  const navigate = useNavigate();
  const [maintenance, setMaintenance] = useState();
  const [isShowModalConfirm, setIsShowModalConfirm] = useState(false);
  const [isShowModalSuccess, setIsShowModalSuccess] = useState(false);
  const [indexTab, setIndexTab] = useState(0);
  const [loading, setLoading] = useState(false);

  const [keyModal, setKeyModal] = useState();

  const [timeCycle, setTimeCycle] = useState({
    id: "",
    value: Number(""),
    item: "",
  });

  const [multipleTiket, setMultipleTiket] = useState({
    id: "",
    value: Number(""),
    item: "",
  });
  const [pointDraw, setPointDraw] = useState({
    id: "",
    value: Number(""),
    item: "",
  });
  const [checkInto, setCheckInTo] = useState({
    id: "",
    value: Number(""),
    item: "",
  });
  const [pointMax, setPointMax] = useState({
    id: "",
    value: Number(""),
    item: "",
  });
  const [checkInForm, setCheckInForm] = useState({
    id: "",
    value: Number(""),
    item: "",
  });
  const [pointMin, setPointMin] = useState({
    id: "",
    value: Number(""),
    item: "",
  });

  const [pointMoney, setPointMoney] = useState([]);
  const [roundArray, setRoundArray] = useState([]);
  const [maintenanceTicket3, setMaintenaaceTicket3] = useState();
  const [authConfig, setAuthConfig] = useState();
  const [pointMoney2, setPointMoney2] = useState([]);
  const [roundArray2, setRoundArray2] = useState([]);
  const [dataMonday, setDataMonday] = useState();
  const [dataTuesday, setDataTuesday] = useState();
  const [dataWednesMonday, setDataWednesday] = useState();
  const [dataThursday, setDataThursday] = useState();
  const [dataFriday, setDataFriday] = useState();
  const [dataSaturday, setDataSaturday] = useState();
  const [dataSunday, setDataSunday] = useState();
  const [dataFullday, setDataFullday] = useState();
  const [maintenanceTicket2, setMaintenanceTicket2] = useState();
  const [time, setTime] = useState();
  const [totalDeposit, setTotalDeposit] = useState();

  useEffect(() => {
    getDataTicketOneStar();
    getDataMaintenance();
    getDataTicket();
    getDataTicket2();
  }, []);

  useEffect(() => {
    if (
      auth?.role === "supper" ||
      (auth?.role === "admin" && roleArr.some((el) => el === "tick_1_edit"))
    ) {
      return setIndexTab(0);
    } else if (
      auth?.role === "supper" ||
      (auth?.role === "admin" && roleArr.some((el) => el === "tick_2_edit"))
    ) {
      return setIndexTab(1);
    } else if (
      auth?.role === "supper" ||
      (auth?.role === "admin" &&
        roleArr.some((el) => el === "tick_3_checkin_edit"))
    ) {
      return setIndexTab(2);
    }
  }, []);

  const getDataTicketOneStar = async () => {
    try {
      const res = await API.get(
        `${COMMON}/all?take=200&skip=1&order=DESC&keyword=%7B%22module%22%3A%22TICKET1%22%7D`
      );
      const data = res?.data?.data?.result[0];

      const newArray = data.map((el) => {
        return {
          id: el.id,
          value: Number(el.value),
          item: el.item,
        };
      });

      setTimeCycle(newArray.find((el) => el.item === "ticket_1_time_cycle"));
      setMultipleTiket(data.find((el) => el.item === "tick_1_multiple"));
      setPointDraw(data.find((el) => el.item === "tick_1_point_draw_n"));
      setCheckInTo(data.find((el) => el.item === "tick_1_checkin_to"));
      setPointMax(data.find((el) => el.item === "tick_1_point_max"));
      setCheckInForm(data.find((el) => el.item === "tick_1_checkin_from"));
      setPointMin(data.find((el) => el.item === "tick_1_point_min"));
    } catch (err) {
      console.log(err);
    }
  };

  const getDataTicket = async () => {
    try {
      const res = await API.get(
        `${COMMON}/all?take=100&skip=1&order=DESC&keyword=%7B"module"%3A"TICKET3"%7D`
      );

      const data = res.data.data.result[0];
      if (res.data.data.code && data) {
        const newArray = data.map((arr) => ({
          id: arr.id,
          value: Number(arr.value),
          value1: Number(arr.value1),
          item: arr.item,
          module: arr.module,
        }));

        setPointMoney(
          newArray.filter((el) => el.item === "ticket_3_award_point").reverse()
        );
        setRoundArray(
          newArray.filter((el) => el.item === "ticket_3_multipe").reverse()
        );

        const dataMain = data.find((el) => el.item === "ticket_3_maintenance");
        setMaintenaaceTicket3({
          value: dataMain.value === "NO" ? false : true,
          id: dataMain.id,
        });
        const dataAuth = data.find((el) => el.item === "ticket_3_pick_user");
        setAuthConfig({
          value: dataAuth.value === "AUTH" ? false : true,
          id: dataAuth.id,
        });
        const newList = data.find((el) => el.item === "ticket_3_time_config");
        const configTime = {
          ...newList,
          value: moment.utc(newList.value, "HH:mm:ss"),
        };
        setTime(configTime);
        setTotalDeposit(
          data.find((el) => el.item === "ticket_3_deposit_condition") ? data.find((el) => el.item === "ticket_3_deposit_condition") : 0
        );
      } else {
        setPointMoney([
          {
            id: "",
            value: 0,
            value1: 0,
            item: "ticket_3_award_point",
            module: "TICKET3",
          },
        ]);
        setPointMoney([
          {
            id: "",
            value: 0,
            value1: 0,
            item: "ticket_3_multipe",
            module: "TICKET3",
          },
        ]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getDataTicket2 = async () => {
    try {
      const res = await API.get(
        `${COMMON}/all?take=100000000000000000&skip=1&order=DESC&keyword=%7B%22module%22%3A%20%22TICKET2%22%7D`
      );
      const data = res.data.data.result[0];

      if (res.data.data.code && data) {
        setDataMonday(
          data
            ? data
              .filter((el) => el.item === "1" && el.itemCode === "TITLE")
              .reverse()
            : []
        );
        setDataTuesday(
          data
            ? data
              .filter((el) => el.item === "2" && el.itemCode === "TITLE")
              .reverse()
            : []
        );
        setDataWednesday(
          data
            ? data
              .filter((el) => el.item === "3" && el.itemCode === "TITLE")
              .reverse()
            : []
        );
        setDataThursday(
          data
            ? data
              .filter((el) => el.item === "4" && el.itemCode === "TITLE")
              .reverse()
            : []
        );
        setDataFriday(
          data
            ? data
              .filter((el) => el.item === "5" && el.itemCode === "TITLE")
              .reverse()
            : []
        );
        setDataSaturday(
          data
            ? data
              .filter((el) => el.item === "6" && el.itemCode === "TITLE")
              .reverse()
            : []
        );
        setDataSunday(
          data
            ? data
              .filter((el) => el.item === "0" && el.itemCode === "TITLE")
              .reverse()
            : []
        );
        setPointMoney2(data.filter((el) => el.itemCode === "OPTION_POINT"));
        setDataFullday(data.filter((el) => el.itemCode === "TITLE"));
        setRoundArray2(data.filter((el) => el.itemCode === "OPTION_MULTIPE"));

        const dataMain = data.find((el) => el.item === "ticket_2_maintenance");
        setMaintenanceTicket2({
          value: dataMain.value === "NO" ? false : true,
          id: dataMain.id,
        });
      }
    } catch (err) { }
  };

  const handleInputChangePoint = useCallback(
    (e, id, field, index) => {
      const newData = pointMoney.map((item, idx) =>
        (id && item.id === id) || index === idx ? { ...item, [field]: e } : item
      );
      setPointMoney(newData);
    },
    [pointMoney]
  );

  const handleChangeInputRound = useCallback(
    (e, id, field, index) => {
      const newData = roundArray.map((item, idx) =>
        (id && item.id === id) || index === idx ? { ...item, [field]: e } : item
      );
      setRoundArray(newData);
    },
    [roundArray]
  );

  const getDataMaintenance = async () => {
    try {
      const res = await API.get(
        `${COMMON}/all?take=100&skip=1&order=DESC&keyword=%7B"module"%3A"TICKET1"%2C"item"%3A"ticket_1_maintenance"%7D`
      );
      const data = res.data.data.result[0][0];
      if (res.data.statusCode === 200 && data) {
        setMaintenance({
          value: data.value === "NO" ? false : true,
          id: data.id,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const showModalConfirm = useCallback((e, key) => {
    e?.preventDefault();
    setIsShowModalConfirm(true);
    setKeyModal(key);
  }, []);

  const handleUpdate = async () => {
    setLoading(true);
    setIsShowModalConfirm(false);
    setIsShowModalSuccess(true);
    switch (keyModal) {
      case "timeCycle":
        return handleUpdateTimeCycle();
      case "multipleTiket":
        return handleUpdatemMultipleTiket();
      case "pointDraw":
        return handleUpdatePointDraw();
      case "checkInto":
        return handleUpdateCheckInto();
      case "pointMax":
        return handleUpdatePointMax();
      case "checkInForm":
        return handleUpdateCheckInForm();
      case "pointMin":
        return handleUpdatePointMin();
      case "ticket3":
        return handleUpdateTicket3();
      case "ticket2":
        return handleUpdateTicket2();
      case "mainTiket2":
        return handleUpdateMainTiket2();
      case "mainticket1":
        return handleUpdateMaintenance();
      case "configtime3":
        return handleUpdateTime();
      case "authConfig3":
        return handleUpdateAuthTiket3();
      case "maintiket3":
        return handleUpdateMainTiket3();
      case "totalDeposit":
        return handleUpdateTotaoDeposit();
    }
  };

  const handleUpdateTotaoDeposit = async () => {
    try {
      const body = {
        item: "ticket_3_deposit_condition",
        value: totalDeposit.value.toString(),
        module: "TICKET3",
      };

      await API.patch(`${COMMON}/${totalDeposit.id}`, body);
      await getDataTicket();
    } catch (err) {
      console.log(err);
    }
  };

  const handleUpdateTimeCycle = async () => {
    try {
      setLoading(true);
      const body = {
        id: timeCycle.id,
        value: (timeCycle?.value).toString(),
      };
      await API.patch(`${COMMON}/${timeCycle.id}`, body);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdatemMultipleTiket = async () => {
    try {
      setLoading(true);

      const body = {
        id: multipleTiket.id,
        value: (multipleTiket?.value).toString(),
      };
      await API.patch(`${COMMON}/${multipleTiket.id}`, body);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdatePointDraw = async () => {
    try {
      setLoading(true);
      const body = {
        id: pointDraw.id,
        value: (pointDraw?.value).toString(),
      };
      await API.patch(`${COMMON}/${pointDraw.id}`, body);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateCheckInto = async () => {
    try {
      setLoading(true);
      const body = {
        id: checkInto.id,
        value: (checkInto?.value).toString(),
      };
      await API.patch(`${COMMON}/${checkInto.id}`, body);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdatePointMax = async () => {
    try {
      setLoading(true);
      const body = {
        id: pointMax.id,
        value: (pointMax?.value).toString(),
      };
      await API.patch(`${COMMON}/${pointMax.id}`, body);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleAddRowMoney = () => {
    // try {
    const newData = {
      module: "TICKET3",
      item: "ticket_3_award_point",
      value: "0",
      value1: "0",
    };

    setPointMoney([...pointMoney, newData]);
    //   const res = await API.post(`${COMMON}/create`, body);
    //   if (res.data.data.message === "Create successfully") {
    //     await getDataTicket();
    //   }
    // } catch (err) {
    //   console.log(err);
    // }
  }

  const handleRemoveItem = async (id, index) => {
    try {
      if (id) {
        const res = await API.delete(`${COMMON}/${id}`);
        if (res.data.data.message === "Delete successfully") {
          await getDataTicket();
        }
      }
      const updateDataPoint = [...pointMoney];
      updateDataPoint.splice(index, 1);
      return setPointMoney(updateDataPoint);

    } catch (err) {
      console.log(err);
    } finally {
      if (id) {
        setIsShowModalSuccess(true)
      }
    }
  }

  const handleRemoveItemRound = async (id, index) => {
    try {
      if (id) {
        const res = await API.delete(`${COMMON}/${id}`);
        if (res.data.data.message === "Delete successfully") {
          await getDataTicket();
        }
      }
      const updateDataRound = [...roundArray];
      updateDataRound.splice(index, 1);
      return setRoundArray(updateDataRound);

    } catch (err) {
      console.log(err);
    } finally {
      if (id) {
        setIsShowModalSuccess(true)
      }
    }
  }

  const handleAddRound = () => {
    // try {
    const newData = {
      module: "TICKET3",
      item: "ticket_3_multipe",
      value: "0",
      value1: "0",
    };

    setRoundArray([...roundArray, newData]);
    //   const res = await API.post(`${COMMON}/create`, body);
    //   if (res.data.data.message === "Create successfully") {
    //     getDataTicket();
    //   }
    // } catch (err) {
    //   console.log(err);
    // }
  }

  const handleUpdateCheckInForm = async () => {
    try {
      setLoading(true);
      const body = {
        id: checkInForm.id,
        value: (checkInForm?.value).toString(),
      };
      await API.patch(`${COMMON}/${checkInForm.id}`, body);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdatePointMin = async () => {
    try {
      setLoading(true);
      const body = {
        id: pointMin.id,
        value: (pointMin?.value).toString(),
      };
      await API.patch(`${COMMON}/${pointMin.id}`, body);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateMaintenance = async () => {
    try {
      const body = {
        id: maintenance?.id,
        value: maintenance.value === false ? "NO" : "YES",
      };
      await API.patch(`${COMMON}/${maintenance?.id}`, body);
      await getDataMaintenance();
    } catch (err) {
      console.log(err);
    }
  };

  const handleUpdateMainTiket3 = useCallback(async () => {
    try {
      setLoading(true);
      const body = {
        id: maintenanceTicket3.id,
        value: maintenanceTicket3.value === false ? "NO" : "YES",
      };
      await API.patch(`${COMMON}/${maintenanceTicket3.id}`, body);
      await getDataTicket();
    } catch (err) {
      setLoading(false);
    } finally {
      setLoading(true);
    }
  }, [maintenanceTicket3]);

  const handleUpdateAuthTiket3 = useCallback(async () => {
    try {
      setLoading(true);
      const body = {
        id: authConfig.id,
        value: authConfig.value === false ? "AUTH" : "FAKE",
      };
      await API.patch(`${COMMON}/${authConfig.id}`, body);
      await getDataTicket();
    } catch (err) {
      setLoading(false);
    } finally {
      setLoading(true);
    }
  }, [authConfig]);

  const handleUpdateTicket3 = async () => {
    try {
      setIsShowModalSuccess(true);
      setLoading(true);
      let data = [];

      if (pointMoney.length > 0) {
        pointMoney.forEach((el) => {
          data.push(el);
        });
      }

      if (roundArray.length > 0) {
        roundArray.forEach((el) => {
          data.push(el);
        });
      }
      const body = {
        sysConfigs: data,
      };

      await API.patch(`${COMMON}/ids`, body);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const handleUpdateMainTiket2 = useCallback(async () => {
    try {
      setLoading(true);
      const body = {
        id: maintenanceTicket2.id,
        value: maintenanceTicket2.value === false ? "NO" : "YES",
      };
      await API.patch(`${COMMON}/${maintenanceTicket2.id}`, body);
      await getDataTicket2();
    } catch (err) {
      setLoading(false);
    } finally {
      setLoading(true);
    }
  }, [maintenanceTicket2]);

  const handleUpdateTicket2 = async () => {
    try {
      setIsShowModalSuccess(true);
      setLoading(true);
      let data = [];

      if (dataMonday.length > 0) {
        dataMonday.forEach((el) => {
          data.push(el);
        });
      }

      if (dataTuesday.length > 0) {
        dataTuesday.forEach((el) => {
          data.push(el);
        });
      }

      if (dataWednesMonday.length > 0) {
        dataWednesMonday.forEach((el) => {
          data.push(el);
        });
      }

      if (dataThursday.length > 0) {
        dataThursday.forEach((el) => {
          data.push(el);
        });
      }

      if (dataFriday.length > 0) {
        dataFriday.forEach((el) => {
          data.push(el);
        });
      }

      if (dataSaturday.length > 0) {
        dataSaturday.forEach((el) => {
          data.push(el);
        });
      }

      if (dataSunday.length > 0) {
        dataSunday.forEach((el) => {
          data.push(el);
        });
      }

      if (pointMoney2.length > 0) {
        pointMoney2.forEach((el) => {
          data.push(el);
        });
      }

      if (roundArray2.length > 0) {
        roundArray2.forEach((el) => {
          data.push(el);
        });
      }

      const body = {
        sysConfigs: data,
      };

      await API.patch(`${COMMON}/ids`, body);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateTime = async () => {

    try {
      const body = {
        value: time.value,
      };
      await API.patch(`${COMMON}/${time.id}`, body);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="col-12">
      <div
        className="col-12"
        style={{
          borderBottom: "1px solid #CCC",
          paddingBottom: "2rem",
          marginBottom: "2rem",
        }}
      >
        <Radio.Group
          value={indexTab}
          onChange={(e) => setIndexTab(e.target.value)}
        >
          {auth?.role === "supper" ||
            (auth?.role === "admin" && roleArr?.indexOf("tick_1_edit") > -1) ? (
            <Radio.Button value={0}>{TEXT_DEF.ve_1_sao}</Radio.Button>
          ) : null}
          {auth?.role === "supper" ||
            (auth?.role === "admin" && roleArr?.indexOf("tick_2_edit") > -1) ? (
            <Radio.Button value={1}>{TEXT_DEF.ve_2_sao}</Radio.Button>
          ) : null}
          {auth?.role === "supper" ||
            (auth?.role === "admin" &&
              roleArr?.indexOf("tick_3_checkin_edit") > -1) ? (
            <Radio.Button value={2}>{TEXT_DEF.ve_3_sao}</Radio.Button>
          ) : null}
        </Radio.Group>
      </div>
      {indexTab === 0 ? (
        <TicketOne
          timeCycle={timeCycle}
          multipleTiket={multipleTiket}
          pointDraw={pointDraw}
          checkInto={checkInto}
          pointMax={pointMax}
          checkInForm={checkInForm}
          pointMin={pointMin}
          maintenance={maintenance}
          setMaintenance={setMaintenance}
          onSetTimeCycle={setTimeCycle}
          onShowModalConfirm={showModalConfirm}
          onSetMultipleTiket={setMultipleTiket}
          onSetPointDraw={setPointDraw}
          onSetCheckInTo={setCheckInTo}
          onSetPointMax={setPointMax}
          onSetCheckInForm={setCheckInForm}
          onSetPointMin={setPointMin}
          onHandleUpdateMaintenance={handleUpdateMaintenance}
          onShowModal={showModalConfirm}
        />
      ) : indexTab === 1 ? (
        <TicketTow
          maintenanceTicket={maintenanceTicket2}
          setMaintenaceTicket={setMaintenanceTicket2}
          onHandleChangeMode={handleUpdateMainTiket2}
          onShowModal={showModalConfirm}
        />
      ) : indexTab === 2 ? (
        <TicketThree
          pointMoney={pointMoney}
          roundArray={roundArray}
          maintenanceTicket3={maintenanceTicket3}
          authConfig={authConfig}
          setMaintenaaceTicket3={setMaintenaaceTicket3}
          setAuthConfig={setAuthConfig}
          onShowModalConfirm={showModalConfirm}
          onHandleInputChangePoint={handleInputChangePoint}
          onHandleChangeInputRound={handleChangeInputRound}
          onHandleAddRowMoney={handleAddRowMoney}
          onHandleRemoveItem={handleRemoveItem}
          onHandleAddRound={handleAddRound}
          onHandleRemoveItemRound={handleRemoveItemRound}
          onHandleUpdateMainTiket3={handleUpdateMainTiket3}
          onHandleUpdateAuthTiket3={handleUpdateAuthTiket3}
          time={time}
          setTime={setTime}
          onSetTime={handleUpdateTime}
          onShowModal={showModalConfirm}
          totalDeposit={totalDeposit}
          setTotalDeposit={setTotalDeposit}
        />
      ) : null}
      {isShowModalConfirm && (
        <ModalCommon
          isShow={isShowModalConfirm}
          message={TEXT_DEF.text0062}
          txtBtnAcc={TEXT_DEF.text0008}
          textBtnClose={TEXT_DEF.text0023}
          actionNext={handleUpdate}
          close={() => setIsShowModalConfirm(false)}
        />
      )}
      {isShowModalSuccess && (
        <ModalCommon
          isShow={isShowModalSuccess}
          message={TEXT_DEF.text0063}
          txtBtnAcc={TEXT_DEF.text0007}
          actionNext={() => {
            setIsShowModalSuccess(false);
            getDataTicketOneStar();
          }}
          close={() => {
            setIsShowModalSuccess(false);
            getDataTicketOneStar();
          }}
        />
      )}
    </div>
  );
};

export default SettingSys;
